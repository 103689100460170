
import { CheckCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { Button, Col, Divider, Layout, Row, Table, TreeSelect } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategoryL3 } from '../../../actions/category/categoryActions';
import HelmetTitle from '../../../shared/Head/HelmetTitle'
import { RootStore } from '../../../Store';
const { Header } = Layout;
const { TreeNode } = TreeSelect

type CategoryType = {
    id:number 
    name:string
    [key: string]: any
}

type AttributeType = {
    id:number 
    category_l3_id: number
    category_name: string
    primary: false
    type: string
    [key: string]: any
}

const AllAtributes = () => {
    const [ selectedL3, setSelectedL3 ] = useState<CategoryType|null>(null)
    const [ attributes, setAttributes ] = useState<AttributeType[]>([])
    const stateProduct = useSelector(( state:RootStore ) => state)

    const currentCountryName =  stateProduct.dataLs.country ? stateProduct.dataLs.country.currentCountry:"rs"
    const currentCountryId =  stateProduct.dataLs.country ? stateProduct.dataLs.country.countryId:1

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getAllCategoryL3(currentCountryId))
    }, [ dispatch, currentCountryId ])

    useEffect(() => {
        const getAttributes = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API }/prices/attributes/list-attribute-names-by-l3?l3_id=${ selectedL3?.id }`)
                setAttributes(data.data)
            } catch ( err ) {
                console.log(err)
            }
        }
        selectedL3?.id && getAttributes()
    }, [ selectedL3?.id ])

    const onCategoryChange = (value:any) => {
        setSelectedL3(value ? JSON.parse(value) : null)
    }

    const columns:any = [
        {
            title: 'Category',
            dataIndex: 'category_name',
            align: 'center' as 'center',
        },
        {
            title: 'Attribute',
            dataIndex: 'name_rs',
            align: 'center' as 'center',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            align: 'center' as 'center',
        },
        {
            title: 'Primary',
            dataIndex: 'primary',
            align: 'center' as 'center',
            render:(record:boolean) => record ? <CheckCircleFilled style={{ color:'green', fontSize:'16px' }} />:<MinusCircleFilled style={{ color:'#ff5757', fontSize:'16px' }} />
        },
        {
            title: 'Action',
            dataIndex: 'id',
            align: 'center' as 'center',
            render:(record:AttributeType) => <Button size='small' >Delete</Button>
        }
    ]

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Attribute List" />  
            <Row justify='center' >
                <Col span={20}   style={{ backgroundColor:'white', padding:'1rem', marginTop:'1rem' }} >
                    <Divider>Attribute list</Divider>
                    <div className='product-img-info-bar' style={{ display:'flex', justifyContent:'center'}}>
                        <TreeSelect
                            showSearch
                            treeDataSimpleMode
                            placeholder="Select category"
                            allowClear
                            style={{
                                width: 330,
                            }}
                            onChange={onCategoryChange}
                            filterTreeNode={(search:any, item:any) => {
                            return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                            }}
                        >
                            {
                            stateProduct.categoriesL3.data && stateProduct.categoriesL3.data.sort((a:any,b:any) => (a[`name_${ currentCountryName }`] > b[`name_${ currentCountryName }`]) ? 1 : ((b[`name_${ currentCountryName }`] > a[`name_${ currentCountryName }`]) ? -1 : 0)).map((category:any) => (
                            category.children.length === 0  ?
                                <TreeNode key={ JSON.stringify({id: category.id, name:category[`name_${ currentCountryName }`] }) } value={ JSON.stringify({id: category.id, name:category[`name_${ currentCountryName }`] }) } title={ `${category.id}: ${category[`name_${ currentCountryName }`]}`}/>:
                                <TreeNode key={ JSON.stringify({id: category.id, name:category[`name_${ currentCountryName }`] }) } value={ JSON.stringify({id: category.id, name:category[`name_${ currentCountryName }`] }) } title={ `${category.id}: ${category[`name_${ currentCountryName }`]}` }>
                                {
                                    category.children.map((itemChild:any) => (
                                    <TreeNode key={JSON.stringify({id: itemChild.id, name:itemChild[`name_${ currentCountryName }`] })}  value={JSON.stringify({id: itemChild.id, name:itemChild[`name_${ currentCountryName }`] })} title={`${itemChild.id}: ${itemChild[`name_${ currentCountryName }`]}`}/>
                                    ))
                                }
                                </TreeNode>
                                )
                            )
                        }
                        </TreeSelect>
                    </div>
                </Col>
            <Col span={20}  style={{ backgroundColor:'white', minHeight:'75vh' }}>
                <div>
                { attributes.length  > 0 &&
                    <Layout style={{ margin:'0.6rem 1rem', backgroundColor:'white', height:'100%' }} >
                        <Header className='product-img-header' > 
                            <span style={{ position:'relative', right:'1.4rem' }} >
                                {`List of attributes for category `} <i style={{ fontSize:'17px' }}>{ selectedL3?.name }</i>
                                    {/* <span style={{ marginLeft:'1rem', fontSize:'12px'}}>{`Page ${ currentPage }`}</span> */}
                            </span> 
                            <Button style={{ position:'absolute', right:'40px', marginTop:'5px', color:'lightgray' }} type="ghost"  size="small">
                                Create Attribute
                            </Button> 
                            </Header>
                            <Table
                                columns={columns}
                                dataSource={attributes}
                                rowKey={record => record.id}
                                pagination={{ pageSize:50, showSizeChanger: false  }}
                            />
                        </Layout>}
                    </div>
            
                </Col>
            </Row>    
        </React.Fragment>
    )
}

export default AllAtributes