import { Button, Col, Form, Input, message, Row, Select, Typography } from "antd";
import React, { useState } from "react";
import { PrimaryAttributesType } from "../../pages/TopProductAttributes";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import { FieldNumberOutlined, PlusOutlined } from "@ant-design/icons";
import ApplyToAllCreateNewValue from "./ApplyToAllCreateNewValue";
import { RootStore } from "../../../../Store";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import numberWithCommas from "../../../../shared/utils/numberUtils";

interface ApplyToAllProps {
    primaryAttributes: PrimaryAttributesType<{
        allowed_value_id: number;
        value: string;
    }>[];
    setPrimaryAttributes: React.Dispatch<React.SetStateAction<PrimaryAttributesType<{ allowed_value_id: number; value: string }>[]>>;
    currentCountryName: string;
    selectedCategory: number | undefined;
    search: string | undefined;
    getProducts: () => Promise<void>;
    stateProduct: RootStore;
}

const ApplyToAll = (props: ApplyToAllProps) => {
    const { primaryAttributes, setPrimaryAttributes, currentCountryName, selectedCategory, search, getProducts, stateProduct } = props;
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [form] = useForm();

    const submitForm = async (e: Record<string, any>) => {
        const isAnyFieldFilled = Object.values(e).some((value) => value !== undefined && value !== "" && value !== null);
        if (!isAnyFieldFilled) {
            openNotification("At least one field has to be filled!");
            return;
        }
        for (let key in e) {
            if (e[key]) {
                const findPrimaryAttribute = primaryAttributes.find((attr) => attr[`name_${currentCountryName}`] === key);

                const obj = {
                    l3_id: selectedCategory,
                    attribute_id: findPrimaryAttribute?.id,
                    attribute_type: findPrimaryAttribute?.type === "yesno" ? "yesNo" : findPrimaryAttribute?.type,
                    attribute_value_id: findPrimaryAttribute?.type === "range" ? null : findPrimaryAttribute?.type === "multiselect" && Array.isArray(e[key]) ? e[key][0] : e[key],
                    value: findPrimaryAttribute?.type === "range" ? Number(e[key]) : 0,
                    search_query: search,
                };

                if (findPrimaryAttribute?.type === "range" && (Number(e[key]) < Number(findPrimaryAttribute.min_value) || Number(e[key]) > Number(findPrimaryAttribute.max_value))) {
                    openNotification(
                        `Range for ${findPrimaryAttribute[`name_${currentCountryName}`]} attriubte have to be between ${findPrimaryAttribute.min_value} and ${findPrimaryAttribute.max_value}`
                    );
                    return;
                }
                setLoadingSubmit(true);
                try {
                    setLoadingSubmit(true);
                    const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/mass-assign-attributes-to-products`, obj);
                    message.success({
                        onClose() {},
                        style: { marginTop: "8rem" },
                        content: (
                            <div>
                                Apply to <span style={{ color: "darkred" }}>{data.data.assigned}</span> products
                            </div>
                        ),
                        // className: "custom-class",
                        duration: 7,
                    });
                    setLoadingSubmit(false);
                    getProducts();
                } catch (err) {
                    console.log(err);
                }
            }
        }
    };

    const [openModal, setOpenModal] = useState(false);
    const [newAttributeValue, setNewAttributeValue] = useState<{ name: string | undefined; attribute_id: number | undefined /*product: ProductType*/ } | null>(null);

    const handleOpenModal = (field: string) => {
        setOpenModal(true);
        setNewAttributeValue({ name: field, attribute_id: primaryAttributes.find((attr) => attr[`name_${currentCountryName}`] === field)?.id });
    };

    const openNewValueModal = (field: any) => {
        setTimeout(() => {
            form.resetFields([field]);
            handleOpenModal(field);
        }, 100);
    };

    const changeSelect = (e: string, field: any) => {
        if (e === "add-button") {
            setTimeout(() => {
                form.resetFields([field]);
                handleOpenModal(field);
            }, 100);
        }
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    const resetForm = () => {
        form.resetFields();
    };

    return (
        <Col span={23} style={{ border: "1px solid lightgray", padding: "1rem", marginTop: "1rem", borderRadius: "8px", backgroundColor: "#F5EFE7" }}>
            <Typography.Title level={4}>Apply to all attributes</Typography.Title>
            <ApplyToAllCreateNewValue
                openModal={openModal}
                handleCancel={handleCancel}
                newAttributeValue={newAttributeValue}
                countries={stateProduct.countries}
                currentCountryName={currentCountryName}
                setPrimaryAttributes={setPrimaryAttributes}
            />
            <Form layout="inline" form={form} onFinish={submitForm}>
                <Row align="middle">
                    {primaryAttributes.map((attr) => (
                        <div key={attr.id}>
                            {attr.type === "range" ? (
                                <div style={{ marginBottom: "0.5rem" }}>
                                    <span style={{ marginLeft: "5px" }}>
                                        {attr[`name_${currentCountryName}`] as string}{" "}
                                        <span style={{ color: "blue" }}>
                                            ({numberWithCommas(attr.min_value.replace(".", ","))} - {numberWithCommas(attr.max_value.replace(".", ","))})
                                        </span>
                                    </span>
                                    {": "}
                                    <Form.Item
                                        // style={{ display: "flex", flexDirection: "column" }}
                                        name={attr[`name_${currentCountryName}`] as string}
                                        // label={attr[`name_${currentCountryName}`] as string}
                                        style={{ width: "400px" }}
                                    >
                                        <Input placeholder={attr[`name_${currentCountryName}`] as string} allowClear addonAfter={<FieldNumberOutlined />} />
                                    </Form.Item>
                                </div>
                            ) : attr.type === "select" ? (
                                <div style={{ marginBottom: "0.5rem" }}>
                                    <span style={{ marginLeft: "5px" }}>{attr[`name_${currentCountryName}`] as string}</span>
                                    {": "}
                                    <Form.Item style={{ width: "400px" }} name={attr[`name_${currentCountryName}`] as string}>
                                        <Select
                                            showSearch
                                            style={{ width: "400px" }}
                                            placeholder={attr[`name_${currentCountryName}`] as string}
                                            allowClear
                                            onChange={(e) => changeSelect(e, attr[`name_${currentCountryName}`])}
                                            optionFilterProp="label"
                                            filterOption={(input, option: any) => (option?.value === "add-button" ? false : (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase()))}
                                        >
                                            {Array.isArray(attr.attribute_values)
                                                ? attr.attribute_values.map(
                                                      (a: PrimaryAttributesType<{ allowed_value_id: number; value: string }>) =>
                                                          a.allowed_value_id &&
                                                          a.value && (
                                                              <Select.Option key={a.allowed_value_id.toString()} value={a.allowed_value_id.toString()}>
                                                                  {a.value.toString()}
                                                              </Select.Option>
                                                          )
                                                  )
                                                : []}
                                            <Select.Option
                                                value={"add-button"}
                                                children={
                                                    <Button size="small" onClick={() => openNewValueModal(attr[`name_${currentCountryName}`])} icon={<PlusOutlined />}>
                                                        Add New
                                                    </Button>
                                                }
                                            />
                                        </Select>
                                    </Form.Item>
                                </div>
                            ) : attr.type === "yesno" ? (
                                <div style={{ marginBottom: "0.5rem" }}>
                                    <span style={{ marginLeft: "5px" }}>{attr[`name_${currentCountryName}`] as string}</span>
                                    {": "}
                                    <Form.Item style={{ width: "400px" }} name={attr[`name_${currentCountryName}`] as string}>
                                        <Select
                                            style={{ width: "400px" }}
                                            placeholder={attr[`name_${currentCountryName}`] as string}
                                            options={
                                                Array.isArray(attr.attribute_values)
                                                    ? attr.attribute_values.map((a: PrimaryAttributesType<{ allowed_value_id: number; value: string }>) => ({
                                                          label: a.value,
                                                          value: a.allowed_value_id,
                                                      }))
                                                    : []
                                            }
                                            allowClear
                                        />
                                    </Form.Item>
                                </div>
                            ) : (
                                <div style={{ marginBottom: "0.5rem" }}>
                                    <span style={{ marginLeft: "5px" }}>{attr[`name_${currentCountryName}`] as string}</span>
                                    {": "}
                                    <Form.Item style={{ width: "400px" }} name={attr[`name_${currentCountryName}`] as string}>
                                        <Select
                                            placeholder={attr[`name_${currentCountryName}`] as string}
                                            options={
                                                Array.isArray(attr.attribute_values)
                                                    ? attr.attribute_values.map((a: PrimaryAttributesType<{ allowed_value_id: number; value: string }>) => ({
                                                          label: a.value,
                                                          value: a.allowed_value_id,
                                                      }))
                                                    : []
                                            }
                                            mode="multiple"
                                            tokenSeparators={[","]}
                                            allowClear
                                        />
                                    </Form.Item>
                                </div>
                            )}
                        </div>
                    ))}

                    <Form.Item style={{ width: "400px", marginTop: "0.8rem" }}>
                        <Button htmlType="submit" type="primary" loading={loadingSubmit}>
                            Apply to all
                        </Button>
                        <Button htmlType="button" danger style={{ marginLeft: "2rem" }} onClick={resetForm}>
                            Reset form
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </Col>
    );
};

export default ApplyToAll;
