import { Col, Row, Table } from "antd";
import React from "react";
import useProductTableColumns from "../../hooks/useProductTableColumns";
import { ProductPricesTableProp } from "../../types/props";

const PopupProductPricesTable: React.FC<ProductPricesTableProp> = (props) => {
    const columns = useProductTableColumns(props.setNewDataInTable, props.productPrices, props.setProductPrices);
    return (
        <Row style={{ backgroundColor: "white", padding: "0.5rem" }}>
            <Col span={24}>
                <Table
                    style={{ width: "1400px" }}
                    //bordered
                    dataSource={props.productPrices}
                    columns={columns}
                    scroll={{ y: "40vh" }}
                    rowKey={(record) => record.id}
                    pagination={false}
                    className="product-prices-table"
                    rowClassName={(record) => (record.active_for_website ? "product-prices-active-price" : "")}
                    size="small"
                />
            </Col>
        </Row>
    );
};

export default PopupProductPricesTable;
