import React, { useCallback, useEffect, useState } from "react";
import { AutoComplete, Button, Checkbox, Col, Divider, Empty, Layout, Popover, Row, Form, Tooltip } from "antd";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getAllCategoryL3 } from "../../../actions/category/categoryActions";
import axios from "axios";
import "../style/AttributeMapping.css";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import showMessage from "../../../shared/MessagesInfo/message";
import errorMessage from "../../../shared/MessagesInfo/ErrorMessage";
import type { ColumnsType } from "antd/es/table";
import { CloseOutlined, PlusCircleFilled, WarningOutlined } from "@ant-design/icons";
import Spinner from "../../../shared/Spinner/Spinner";
import openTopNotification from "../../../shared/MessagesInfo/WarningBoxTop";
import CategorySelect, { CateogoryL3L4 } from "../../../shared/FormElements/CategorySelect";
import { AttributeMappingRulesType, Rules } from "./AttributeMappingRules";
import CreateDefintionPopover from "../components/AttributeMapping/CreateDefintionPopover";
import AttributeMappingTabs from "../components/AttributeMapping/AttributeMappingTabs";
import AttributeDefinition from "../components/AttributeMapping/AttributeDefinition";
import { v4 as uuidv4 } from "uuid";
import ShopValuesTable from "../components/AttributeMapping/ShopValuesTable";
import AttributeValuesTable from "../components/AttributeMapping/AttributeValuesTable";
import { AttirbuteDefRulesType, AttributeType, SimilarAttributType, SimilarDataType, ValuesType, ValueType } from "../types/AttributeType";
import { capitalizeFirstLetter } from "../helpers/checkIsIncludes";
import ExsistingAttributes from "../components/AttributeMapping/ExsistingAttributes";
import { useSearchState } from "../../../shared/hooks/useSearchState";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
//const  { Header } = Layout

const AttributeMapping = () => {
    const [categories, setCategories] = useState<CateogoryL3L4[]>([]);
    const [selectedCategory, setSelectedCateogry] = useState<{
        id: number;
        name: string;
    } | null>(null);
    const [attributesByGroup, setAttributesByGroup] = useState<{ group_id: number; group_name: string; data: { group_id: number; group_name: string }[] }[]>([]);
    const [attributeList, setAttributeList] = useState<AttributeType[]>([]);
    const [similarAttributes, setSimilarAttributes] = useState<SimilarAttributType[]>([]);
    const [values, setValues] = useState<ValueType | null>(null);
    const [valuesShop, setValuesShop] = useState<ValueType | null>(null);

    const [loading, seLoading] = useState(false);
    //const [ loadingAttributeList, setLoadingAttributeList ] = useState(false)
    const [selectedCustomMapping, setSelectedCustomMapping] = useState<string | null>(null);
    const [, setLoading] = useState(false);
    const dispatch = useDispatch();
    const state = useSelector((state: RootStore) => state);
    const [form] = Form.useForm();
    const [activeTabKey, setActiveTabKey] = useState<null | number>(null);

    const [tabValue, setTabValue] = useState<number | null>(null); ///////////////////////////////////////
    const [tableData, setTableData] = useState<SimilarAttributType | null>(null);
    const [customOptions, setCustomOptions] = useState<AttributeType[]>([]);
    const [loadingCustumMapping, setLoadingCustomMapping] = useState(false);
    ////////////////////////////////////
    const currentCountryName = state.dataLs.country ? state.dataLs.country.currentCountry : "rs";
    const currentCountryId = state.dataLs.country ? state.dataLs.country.countryId : 1;

    useEffect(() => {
        const findTableData = similarAttributes.find((a) => a.shop_id === tabValue);
        findTableData && setTableData(findTableData);
    }, [tabValue, similarAttributes]);

    useEffect(() => {
        dispatch(getAllCategoryL3(currentCountryId));
    }, [dispatch, currentCountryId]);

    useEffect(() => {
        setCategories(
            state.categoriesL3.data &&
                state.categoriesL3.data.sort((a: CateogoryL3L4, b: CateogoryL3L4) =>
                    a[`name_${currentCountryName}` as keyof CateogoryL3L4] > b[`name_${currentCountryName}` as keyof CateogoryL3L4]
                        ? 1
                        : b[`name_${currentCountryName}` as keyof CateogoryL3L4] > a[`name_${currentCountryName}` as keyof CateogoryL3L4]
                        ? -1
                        : 0
                )
        );
    }, [state.categoriesL3.data, currentCountryName]);

    type ObjectGroup = { group_id: number; [key: string]: string | number };

    type GroupType = {
        group_id: number;
        group_name: string;
        data: { group_id: number; [key: string]: string | number }[];
    }[];

    const getData = useCallback(
        async (load: boolean) => {
            setValues(null);
            setValuesShop(null);
            if (selectedCategory?.id) {
                load && setLoading(true);

                try {
                    const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-attributes-with-value-count?l3=${selectedCategory.id}`);
                    setAttributeList(
                        data.data
                            .map((item: AttributeType) => {
                                return { ...item, isMergeValues: false };
                            })
                            .sort((a: AttributeType, b: AttributeType) => {
                                if (a.order === 0) return 1; //Return 1 so that b goes first
                                if (b.order === 0) return -1; //Return -1 so that a goes first
                                return a.order && b.order && a.order - b.order;
                            })
                    );

                    load && setLoading(false);
                } catch (err) {
                    console.log(err);
                    load && setLoading(false);
                }
            }
        },
        [selectedCategory]
    );

    useEffect(() => {
        const getCustomOptions = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-attributes-with-value-count?l3=${selectedCategory?.id}&list_all=1`);
                setCustomOptions(data.data);
                // setAttributeList(
                //     data.data
                //         .map((item: AttributeType) => {
                //             return { ...item, isMergeValues: false };
                //         })
                //         .sort((a: AttributeType, b: AttributeType) => {
                //             if (a.order === 0) return 1; //Return 1 so that b goes first
                //             if (b.order === 0) return -1; //Return -1 so that a goes first
                //             return a.order && b.order && a.order - b.order;
                //         })
                // );
            } catch (err) {
                console.log(err);
            }
        };

        selectedCategory?.id && getCustomOptions();
    }, [selectedCategory?.id]);

    useEffect(() => {
        const getAttributesByGroup = async (selectedCategoryId: number) => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-attribute-with-groups-and-counts?l3=${selectedCategoryId}`);
                let groupedAttributeByGroup = data.data.reduce((acc: GroupType, obj: ObjectGroup) => {
                    let group = acc.find((g: { group_id: number }) => g.group_id === obj.group_id);

                    if (group) {
                        group.data.push(obj);
                    } else {
                        acc.push({
                            group_id: obj.group_id,
                            group_name: typeof obj.group_rs === "string" ? obj.group_rs : "No Group",
                            data: [obj],
                        });
                    }

                    return acc;
                }, []);
                setAttributesByGroup(groupedAttributeByGroup.sort((a: { group_id: number }, b: { group_id: number }) => a.group_id - b.group_id));
            } catch (err) {
                console.log(err);
            }
        };
        selectedCategory && getAttributesByGroup(selectedCategory.id);
    }, [selectedCategory]);

    const getSimilarAttributes = useCallback(
        async (load: boolean, changeActiveTabKey?: boolean) => {
            if (selectedCategory?.id) {
                load && seLoading(true);
                try {
                    const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-similar-price-attributes-by-l3?l3=${selectedCategory.id}`);
                    let result: SimilarAttributType[] = Object.values(
                        [
                            ...data.data.mapped.map((d: SimilarDataType) => ({
                                ...d,
                                mapped: true,
                                isLoading: false,
                                isSelect: false,
                                id: uuidv4(),
                            })),
                            ...data.data.similarities.map((d: SimilarDataType) => ({
                                ...d,
                                mapped: false,
                                isLoading: false,
                                isSelect: false,
                                id: uuidv4(),
                            })),
                        ].reduce((a, { shop_id, shop_name, ...props }: SimilarAttributType) => {
                            if (!a[shop_id])
                                a[shop_id] = Object.assign(
                                    {},
                                    {
                                        shop_id,
                                        shop_name: shop_name,
                                        data: [{ ...props, shop_id, shop_name, customMapping: false }],
                                    }
                                );
                            else
                                a[shop_id].data.push({
                                    ...props,
                                    shop_id,
                                    shop_name,
                                    customMapping: false,
                                });
                            return a;
                        }, {})
                    );
                    setSimilarAttributes(result);
                    load && seLoading(false);
                } catch (err) {
                    console.log(err);
                    load && seLoading(false);
                }
            }
        },
        [selectedCategory]
    );

    useEffect(() => {
        if (selectedCategory?.id) {
            getData(true);
            getSimilarAttributes(true);
        }
    }, [selectedCategory, getData, getSimilarAttributes]);

    const [arrToCompare, setArrToCompare] = useState<string[]>([]);
    const [rangeToCompare, setRangeToCompare] = useState<{
        min: number;
        max: number;
    } | null>(null);

    const showOnlyRange = async (attribute_id: number) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/get-attribute-data?attribute_id=${attribute_id}`);
            setRangeToCompare({
                min: Number(data.data.min_value),
                max: Number(data.data.max_value),
            });
        } catch (err) {
            console.log(err);
        }
    };

    const showAttValue = async (record: SimilarDataType, type: string, toCompare: boolean) => {
        // toCompare && setValues(null)
        //!toCompare && setLoadingAttributeList(true)
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/list-values-with-count?attribute_id=${record?.attribute_id}`);
            setValues({
                name: record?.name_rs,
                type: type,
                attribute_type: record.attribute_type,
                isSelect: false,
                attribute_id: record.attribute_id,
                isMapping: record.isMapping,
                data: data.data.map((item: ValuesType, index: number) => {
                    index === 0 &&
                        setRangeToCompare({
                            min: Number(item.range_min),
                            max: Number(item.range_max),
                        });
                    return { ...item, isMergeValues: false };
                }),
            });
            data.data.length === 0 && showOnlyRange(record.attribute_id);
            setArrToCompare(data.data.map((item: ValuesType) => item[`value_${currentCountryName}` as keyof ValuesType]));
        } catch (err) {
            console.log(err);
        }
    };

    const showShopAttValue = async (record: SimilarDataType, type: string, isSelect: boolean) => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/attribute-key-mapping/list-shop-attributes-by-l3-and-key?key=${record.key}&l3_id=${record.category_l3_id}&shop_id=${record.shop_id}`
            );
            setValuesShop({
                name: data.data[0].key,
                type: type,
                attribute_type: record.attribute_type,
                isSelect: isSelect,
                attribute_id: record.attribute_id,
                isMapping: record?.mapping_id ? true : false,
                data: data.data.map((item: { value: string; id: number }) => ({
                    ...item,
                    value_rs: item.value,
                    value_id: item.id,
                })),
            });

            setSimilarAttributes((curr) =>
                curr.map((s) =>
                    s.shop_id === record.shop_id
                        ? {
                              ...s,
                              data: s.data.map((a) => ({
                                  ...a,
                                  isSelect: a.id === record.id ? true : false,
                              })),
                          }
                        : s
                )
            );
        } catch (err) {
            console.log(err);
        }
    };

    const [mappingLoading, setMappingLoading] = useState(false);

    const saveMapAttribute = async (e: CheckboxChangeEvent, record: SimilarDataType) => {
        setMappingLoading(true);
        const newData = similarAttributes.map((shop) => {
            if (shop.shop_id === record.shop_id) {
                return {
                    ...shop,
                    data: shop.data.map((d) => (d.id === record.id ? { ...d, isLoading: true } : d)),
                };
            }
            return shop;
        });
        setTableData((curr) => (curr ? { ...curr, data: curr.data.map((d) => (d.id === record.id ? { ...d, isLoading: true } : d)) } : null)); //?????
        setSimilarAttributes(newData);

        const objToSend = {
            shop_id: record.shop_id,
            key: record.key,
            l3_id: record.category_l3_id,
            attribute_id: record.attribute_id,
        };

        try {
            const { data } = await axios[e.target.checked ? "post" : "delete"](
                `${process.env.REACT_APP_URL_API}${`/prices/attribute-key-mapping/${e.target.checked ? "create" : `delete-mapping-by-id?mapping_id=${record.mapping_id}`}`}`,
                e.target.checked && objToSend
            );
            const newData = similarAttributes.map((shop) => {
                if (shop.shop_id === record.shop_id) {
                    return {
                        ...shop,
                        data: shop.data.map((d) =>
                            d.id === record.id
                                ? {
                                      ...d,
                                      isLoading: false,
                                      mapping_id: e.target.checked ? data.data : null,
                                      mapped: e.target.checked ? true : false,
                                  }
                                : d
                        ),
                    };
                }
                return shop;
            });
            setSimilarAttributes(newData);
            showMessage(data.message);
            setMappingLoading(false);
        } catch (err) {
            console.log(err);
            errorMessage("Error, Sometnig went wrong!");
        }
    };

    const openCustomPopover = (record: SimilarDataType) => {
        const findShop = similarAttributes.find((s) => s.shop_id === record.shop_id);
        setSimilarAttributes((curr) =>
            curr.map((s) => {
                if (s.shop_id === findShop?.shop_id) {
                    const dataInData = s.data.map((d) => (d.id === record.id ? { ...d, customMapping: true } : { ...d, customMapping: false }));
                    return {
                        ...s,
                        data: dataInData,
                    };
                }
                return s;
            })
        );
    };

    const hide = () => {
        setSimilarAttributes((curr) =>
            curr.map((s) => {
                const dataInData = s.data.map((d) => ({ ...d, customMapping: false }));
                return {
                    ...s,
                    data: dataInData,
                };
            })
        );
    };

    const saveCustomMapping = async (record: SimilarDataType) => {
        const objToSend = {
            shop_id: record.shop_id,
            key: record.key,
            l3_id: selectedCategory?.id,
            attribute_id: customOptions.find((a) => a[`name_${currentCountryName}` as keyof typeof a] === selectedCustomMapping)?.id, //record.attribute_id  //selectedAttributer.id
        };
        // console.log(tableData?.data);
        // console.log(record.key);
        const findMappedAttribute = tableData?.data.find((attr) => attr.key === record.key && attr.mapping_id);

        if (findMappedAttribute) {
            openNotification("Attribute is already mapped");
            return;
        }

        setLoadingCustomMapping(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attribute-key-mapping/create`, objToSend);
            showMessage(data.message);
            setSelectedCustomMapping(null);
            hide();
            setLoadingCustomMapping(false);
            getSimilarAttributes(false);
        } catch (err) {
            console.log(err);
        }
    };
    const showAttAndShopAtt = (record: SimilarDataType) => {
        showAttValue(record, "Attribute", false);
        showShopAttValue(record, "Shop Attribute", record.attribute_type === "select" || record.attribute_type === "multiselect" ? true : false);
        setAttributeDefRules(null);
    };

    const sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || "").localeCompare(b || "") : a - b);

    const { getColumnSearchProps } = useSearchState(attributeList);

    const columns: ColumnsType<SimilarDataType> = [
        {
            title: "Type",
            dataIndex: `attribute_type`,
            width: "12%",
            align: "center" as "center",
            sorter: (a: SimilarDataType, b: SimilarDataType) => sorter(a.attribute_type, b.attribute_type),
            render: (_, record) => (
                <div>
                    {capitalizeFirstLetter(record.attribute_type)}{" "}
                    <span style={{ width: "40px" }}>
                        {record.attribute_type === "list" || record.attribute_type === "filter" ? (
                            <Tooltip title="You need to change attribute type to something else!">
                                {" "}
                                <WarningOutlined style={{ color: "orange" }} />
                            </Tooltip>
                        ) : (
                            "  "
                        )}
                    </span>
                </div>
            ),
        },
        {
            title: "Attribute",
            dataIndex: `name_rs`,
            width: "30%",
            ...getColumnSearchProps(`name_rs`),
            sorter: (a: SimilarDataType, b: SimilarDataType) => sorter(a.name_rs, b.name_rs),
            render: (_: string, record: SimilarDataType) => (
                <div onClick={() => showAttAndShopAtt(record)} className="attribute-mapping-name">
                    <span>{record[`name_rs`]}</span>
                </div>
            ),
        },
        {
            title: "Shop Att",
            dataIndex: "key",
            width: "25%",
            className: "attribute-mapping-shop-att-td",
            ...getColumnSearchProps(`key`),
            sorter: (a: SimilarDataType, b: SimilarDataType) => sorter(a.key, b.key),
            render: (text, record) => (
                <div style={{ display: "flex", justifyContent: "space-between" }} onClick={() => showAttAndShopAtt(record)} className="attribute-mapping-name">
                    <span>{text}</span>
                </div>
            ),
        },
        {
            title: " ",
            dataIndex: "key",
            className: "attribute-mapping-custom-td",
            render: (text, record) =>
                !record.mapping_id && (
                    <Popover
                        content={
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <AutoComplete
                                    style={{ width: 350, marginRight: "20px" }}
                                    options={
                                        customOptions?.length > 0 //////////////////////////////////////////////////////////////////////////
                                            ? customOptions
                                                  ?.filter((d) => d.id !== record.attribute_id)
                                                  .map((d) => {
                                                      return {
                                                          value: d[`name_${currentCountryName}` as keyof typeof d],
                                                          new_id: d.id,
                                                      };
                                                  })
                                            : []
                                    }
                                    placeholder="Search Attributes"
                                    filterOption={(inputValue, option) => option!.value?.toString()?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                                    onChange={
                                        /*setNewValueHandler*/ (e) => {
                                            setSelectedCustomMapping(e);
                                        }
                                    }
                                    allowClear
                                    onClear={() => setSelectedCustomMapping(null)}
                                />
                                <Button onClick={() => saveCustomMapping(record /*record.attribute_id, record.name)*/)} disabled={selectedCustomMapping ? false : true} loading={loadingCustumMapping}>
                                    Map
                                </Button>
                            </div>
                        }
                        title={
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                Custom Mapping
                                <CloseOutlined style={{ cursor: "pointer", marginTop: "3px" }} onClick={hide} />
                            </div>
                        }
                        trigger="click"
                        open={record.customMapping}
                        placement="right"
                    >
                        <Button
                            size="small"
                            onClick={() => openCustomPopover(record)}
                            style={{
                                backgroundColor: record.isSelect ? "#F5B7B1" : "",
                                //border: "1px solid white",
                            }}
                        >
                            Custom
                        </Button>
                    </Popover>
                ),
        },
        {
            title: "Sim",
            dataIndex: "sim",
            align: "center" as "center",
            sorter: (a: SimilarDataType, b: SimilarDataType) => sorter(a.sim, b.sim),
            render: (record: SimilarDataType) => <span>{Number(record) === 2 ? "" : `${(Number(record) * 100).toFixed(0)} %`}</span>,
        },
        {
            title: "Count",
            dataIndex: "attribute_count",
            align: "center" as "center",
            sorter: (a: SimilarDataType, b: SimilarDataType) => sorter(a.attribute_count, b.attribute_count),
            render: (record) => <span>{record}</span>,
        },
        {
            title: "Mapped",
            dataIndex: ``,
            align: "center" as "center",
            render: (_: string, record: SimilarDataType) => (
                <Checkbox
                    checked={record.mapping_id ? true : false}
                    onChange={(e) => {
                        !mappingLoading && saveMapAttribute(e, record);
                    }}
                ></Checkbox>
            ),
            width: "7%",
        },
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const openModalAlloweValue = (data: ValuesType) => {
        if (valuesShop?.isMapping) {
            setIsModalOpen(true);

            // state.countries.map(c => (

            // ))

            const obj: Record<string, string | boolean | number> = {};
            state.countries.forEach((c) => {
                obj[`value_${c.name}`] = data.temporary_clean_value ? data.temporary_clean_value : data[`value_${currentCountryName}` as keyof typeof data]; // e[`value_rs`]
            });

            form.setFieldsValue({
                ...obj,
                value_en: data.temporary_clean_value ? data.temporary_clean_value : data[`value_${currentCountryName}` as keyof typeof data],
            });
        } else {
            openTopNotification("It's not allowed because Attribute is not mapped!");
        }
    };

    const saveNewAllowedValue = async (e: EventListenerObject & { value_rs: string }) => {
        const objToSend = {
            attribute_id: values?.attribute_id,
            ...e,
        };
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/create-attribute-value`, objToSend);
            setIsModalOpen(false);
            showMessage(data.message);
            setArrToCompare((curr) => [...curr, e.value_rs]);
        } catch (err) {
            console.log(err);
        }
    };

    const onCategoryChange = (e: number) => {
        state.categoriesL3.data.forEach((category3: (typeof state.categoriesL3.data)[0]) => {
            if (category3.id === e) {
                setSelectedCateogry({
                    id: category3.id,
                    name: category3[`name_${currentCountryName}`],
                });
                return;
            }
            category3?.children.length > 0 &&
                category3.children.forEach((categoryl4: (typeof state.categoriesL3.data)[0]) => {
                    if (categoryl4.id === e) {
                        setSelectedCateogry({
                            id: categoryl4.id,
                            name: categoryl4[`name_${currentCountryName}`],
                        });
                        return;
                    }
                });
        });
    };
    ///////////////----------- Attribute mapping rules -----------
    const [attirbuteRules, setAttributeRules] = useState<AttributeMappingRulesType[]>([]);
    const getAttributeMapping = useCallback(async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attribute-value-cleaning/index`);
            const newData = data.data.map((item: Partial<AttributeMappingRulesType>) => ({
                ...item,
                isChangeFormula: false,
                rules: typeof item.rules === "string" ? JSON.parse(item.rules) : [],
                isChangeName: false,
                key: item.id?.toString(),
            }));
            setAttributeRules(newData);
        } catch (err) {
            console.log(err);
        }
    }, []);

    ///////////------------ Attribute definiton ---------

    const [attirbuteDefRules, setAttributeDefRules] = useState<AttirbuteDefRulesType[] | null>(null);

    const getAttributeDefRules = useCallback(async (obj: { shop_id: number | null; key: string; l3_id: number | undefined }) => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attribute-value-cleaning/get-cleaning-data?shop_id=${obj.shop_id}&key=${obj.key}&l3_id=${obj.l3_id}`);

            setAttributeDefRules(
                data.data.map((item: AttirbuteDefRulesType) => ({
                    ...item,
                    parameter_values: typeof item.parameter_values === "string" ? JSON.parse(item.parameter_values) : [],
                }))
            );
        } catch (err) {
            console.log(err);
        }
    }, []);

    useEffect(() => {
        valuesShop &&
            getAttributeDefRules({
                shop_id: activeTabKey,
                key: valuesShop?.name,
                l3_id: selectedCategory?.id,
            });
    }, [getAttributeDefRules, valuesShop, activeTabKey, selectedCategory?.id]);

    useEffect(() => {
        getAttributeMapping();
    }, [getAttributeMapping]);

    const [selectedAttrRules, setSelectedAttrRules] = useState<Rules[] | string | undefined>([]);
    const [popoverAttDef, setPopoverAttDef] = useState(false);

    const onOpenAttrDef = (e: boolean) => {
        if (!e) {
            setSelectedAttrRules([]);
            form.resetFields();
        }
        setPopoverAttDef(e);
    };

    const saveNewRuleDef = async (e: { rule_id: number }) => {
        var result = Object.keys(e)
            .map((key) => [key, e[key as keyof { rule_id: number }]])
            .filter((item) => item[0] !== "rule_id");

        const parameter_values = result.map((param) => ({
            acr_id: e.rule_id,
            parameter_id: Number(param[0]),
            parameter_value: param[1],
            definition_id: 2222,
        }));

        const objectToSend = {
            rule_id: e.rule_id,
            shop_id: activeTabKey,
            attribute_category_id: selectedCategory?.id,
            key: valuesShop?.name,
            parameter_values: parameter_values,
        };

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attribute-value-cleaning/create-rule-definition`, objectToSend);
            showMessage(data.message);
            setPopoverAttDef(false);
            form.resetFields();
            setSelectedAttrRules([]);
            valuesShop &&
                getAttributeDefRules({
                    shop_id: activeTabKey,
                    key: valuesShop?.name,
                    l3_id: selectedCategory?.id,
                });
        } catch (err) {
            console.log(err);
        }
    };

    const applyDefiniton = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/attribute-value-cleaning/get-applied-formulas?shop_id=${activeTabKey}&key=${valuesShop?.name}&l3_id=${selectedCategory?.id}`
            );
            showMessage(data.message);
            const findRecord = similarAttributes.find((a) => a.shop_id === activeTabKey)?.data.find((d) => d.key === valuesShop?.name);
            findRecord && showShopAttValue(findRecord, "Shop Attribute", false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Attributes Mapping" />
            <Row justify="center">
                <Col
                    span={/*windowSize.width > 1700 ? 23 :*/ 23}
                    style={{
                        backgroundColor: "white",
                        padding: "0rem",
                        marginTop: "1rem",
                    }}
                >
                    <Divider>Attribute Mapping</Divider>
                    <div
                        /*className='product-img-info-bar'*/ style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CategorySelect categoryL3Options={categories} currentCountry={currentCountryName} onCategoryChange={onCategoryChange} style={{ width: "300px" }} />
                    </div>
                </Col>
                {selectedCategory?.id ? (
                    <React.Fragment>
                        <Col span={/*windowSize.width > 1700 ? 23 :*/ 23} style={{ backgroundColor: "white", paddingTop: "1rem" }}>
                            <Layout style={{ margin: "0 1rem 1rem 1rem" }}>
                                {/* <Header className='product-img-header'><span></span> </Header> */}
                                <Row justify="center" className="attribute-mapping-box" style={{ scrollbarWidth: "thin" }}>
                                    <Col span={4} style={{ height: "81.4vh", overflow: "auto" }} className="exsisting-attributes-box">
                                        <div style={{ margin: "0.5rem" }}>
                                            <ExsistingAttributes loading={loading} attributesByGroup={attributesByGroup} currentCountryName={currentCountryName} />
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        {loading ? (
                                            <Spinner />
                                        ) : similarAttributes.length > 0 ? (
                                            <AttributeMappingTabs
                                                similarAttributes={similarAttributes}
                                                columns={columns}
                                                setTabValue={setTabValue}
                                                setActiveTabKey={setActiveTabKey}
                                                tabValue={tabValue}
                                                tableData={tableData}
                                            />
                                        ) : loading ? (
                                            <Spinner />
                                        ) : (
                                            <div className="matching-seller-category-empty">
                                                <Empty description="No data for selected Cateogry" />
                                            </div>
                                        )}
                                    </Col>
                                    <Col span={8} style={{ padding: "1rem" }}>
                                        <Row className={`${values && valuesShop && attirbuteDefRules ? "attribute-mapping-values-box" : ""}`}>
                                            <Col span={12}>
                                                <AttributeValuesTable attirbuteDefRules={attirbuteDefRules} rangeToCompare={rangeToCompare} values={values} valuesShop={valuesShop} />
                                            </Col>
                                            <Col span={12}>
                                                {values && valuesShop && attirbuteDefRules && (
                                                    <div
                                                        style={{
                                                            height: "77.5vh",
                                                            padding: "0 0px",
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            alignContent: "stretch",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                border: "1px solid lightgray",
                                                                padding: "6px",
                                                                borderRadius: "8px",
                                                                marginTop: "3px",
                                                                margin: "0 0.3rem",
                                                            }}
                                                        >
                                                            {similarAttributes.length > 0 && (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <Popover
                                                                        placement="leftTop"
                                                                        title={<p style={{ textAlign: "center" }}>Create Attribute Definition</p>}
                                                                        content={
                                                                            <CreateDefintionPopover
                                                                                form={form}
                                                                                selectedAttrRules={selectedAttrRules}
                                                                                setSelectedAttrRules={setSelectedAttrRules}
                                                                                attirbuteRules={attirbuteRules}
                                                                                saveNewRuleDef={saveNewRuleDef}
                                                                            />
                                                                        }
                                                                        onOpenChange={(e) => onOpenAttrDef(e)}
                                                                        open={popoverAttDef}
                                                                        trigger="click"
                                                                    >
                                                                        <PlusCircleFilled
                                                                            style={{
                                                                                fontSize: "26px",
                                                                                marginRight: "7px",
                                                                                marginTop: "3px",
                                                                                color: "#55c59d",
                                                                                textAlign: "center",
                                                                            }}
                                                                        />
                                                                    </Popover>

                                                                    <Button style={{ marginTop: "3px" }} size="small" onClick={applyDefiniton}>
                                                                        Apply
                                                                    </Button>
                                                                </div>
                                                            )}
                                                            <AttributeDefinition
                                                                attirbuteDefRules={attirbuteDefRules}
                                                                setAttributeDefRules={setAttributeDefRules}
                                                                getAttributeDefRules={getAttributeDefRules}
                                                                values={valuesShop}
                                                                activeTabKey={activeTabKey}
                                                                selectedCategory={selectedCategory}
                                                            />
                                                        </div>
                                                        <ShopValuesTable
                                                            arrToCompare={arrToCompare}
                                                            openModalAlloweValue={openModalAlloweValue}
                                                            rangeToCompare={rangeToCompare}
                                                            values={values}
                                                            valuesShop={valuesShop}
                                                            form={form}
                                                            handleOk={handleOk}
                                                            handleCancel={handleCancel}
                                                            isModalOpen={isModalOpen}
                                                            saveNewAllowedValue={saveNewAllowedValue}
                                                            activeTabKey={activeTabKey}
                                                            selectedCategory={selectedCategory}
                                                            countries={state.countries}
                                                        />
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Layout>
                        </Col>
                    </React.Fragment>
                ) : (
                    <Col
                        style={{
                            height: "75vh",
                            width: "95.9vw",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="matching-seller-category-empty">
                            <Empty description="No data, select Category" />
                        </div>
                    </Col>
                )}
            </Row>
        </React.Fragment>
    );
};

export default AttributeMapping;
