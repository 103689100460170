import { Button, Form, Input, Modal, Space } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { RootStore } from "../../../../Store";
import axios from "axios";
import showMessage from "../../../../shared/MessagesInfo/message";
import { DataType } from "./AttributeGroup";

interface AttributeGroupModalProps {
    isCreateGroup: boolean;
    setIsCreateGroup: React.Dispatch<React.SetStateAction<boolean>>;
    setGroups: React.Dispatch<React.SetStateAction<DataType[]>>;
    form: FormInstance;
    state: RootStore;
    selectedCategory: {
        id: number;
        name: string;
    } | null;
}

const AttributeGroupModal = (props: AttributeGroupModalProps) => {
    const { isCreateGroup, setIsCreateGroup, setGroups, form, state, selectedCategory } = props;

    const saveNewGroup = async (e: { [key: string]: string | number | undefined }) => {
        if (e.id) {
            // edit
            const obj: { [key: string]: string | number | undefined; id?: number } = {
                ...e,
                name: e.name_rs,
                group_id: e.id,
                l3_id: selectedCategory?.id,
            };
            delete obj["id" as keyof typeof obj];
            try {
                const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/attributes/update-attribute-group`, obj);
                setGroups((curr) => curr.map((d) => (d.id === e.id ? { ...d, ...obj, id: d.id } : d)));
                showMessage(data.message);
            } catch (err) {
                console.log(err);
            }
        } else {
            // create
            const obj: { [key: string]: string | number | undefined; id?: number } = {
                ...e,
                name: e.name_rs,
                l3_id: selectedCategory?.id,
            };

            try {
                const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/create-attribute-group`, obj);
                setGroups((curr) => [...curr, { ...obj, id: data.data, key: data.data.toString() }]);
                showMessage(data.message);
            } catch (err) {
                console.log(err);
            }
        }
    };

    const checkId = Form.useWatch("id", props.form);
    return (
        <Modal
            open={isCreateGroup}
            footer={false}
            title="Create new Attribute group"
            onCancel={() => {
                setIsCreateGroup(false);
                form.resetFields();
            }}
        >
            <Form
                form={form}
                onFinish={saveNewGroup}
                style={{
                    border: "1px solid lightgray",
                    borderRadius: "5px",
                    padding: "1rem",
                }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
            >
                {state.countries.map((c) => (
                    <Form.Item key={c.name} name={`name_${c.name}`} label={`Name ${c.name.toUpperCase()}`} rules={[{ required: true, message: `${c.name.toUpperCase()} name is required` }]}>
                        <Input placeholder={`Name ${c.name.toUpperCase()}`} />
                    </Form.Item>
                ))}
                <Form.Item name="name_en" label="Name EN" rules={[{ required: true, message: "Name EN is required" }]}>
                    <Input placeholder="Name EN" />
                </Form.Item>

                <Form.Item name="id" style={{ display: "none" }}>
                    <Input />
                </Form.Item>

                <Form.Item {...{ wrapperCol: { offset: 8, span: 16 } }} style={{ margin: "0" }}>
                    <Space style={{ marginTop: "1rem" }}>
                        <Button type="primary" htmlType="submit">
                            {checkId ? "Edit" : "Save"}
                        </Button>
                        <Button htmlType="button" danger onClick={() => setIsCreateGroup(false)}>
                            Close
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AttributeGroupModal;
