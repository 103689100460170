import React from 'react'
import { Row, Col, Divider, Select, Table, Button, Spin, Input } from 'antd'
import { RootStore } from '../../../Store';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryL3L4 } from '../../../actions/category/categoryActions';
import axios from "axios";
import AttributeModal from '../components/AttributeModal';
import { useModal } from '../components/useModal';
import '../style/AttributeProductList.css'
import HelmetTitle from '../../../shared/Head/HelmetTitle';
const AttributeProductList: React.FC = () => {
    const state = useSelector((state: RootStore) => state)
    const dispatch = useDispatch()
    const [selectedL3, setSelectedL3] = useState<any>('')
    const [search, setSearch] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [products, setProducts] = useState<any>(null);
    const [dataProducts, setDataProducts] = useState<any[]>();
    const { isShown, toggle, product, handleProduct, data, isChanged, change, setIsChanged } = useModal();

    useEffect(() => {
        dispatch(getCategoryL3L4())
    }, [dispatch])

   

    useEffect(() => {
        const searchProduct = () => {

            let str = search && search.replaceAll('\\', '').toLowerCase();
    
            return products.products.filter((p: any) => (
                p.product_id.toString().search(str) >= 0 ||
                (p.name && p.name.toLowerCase().search(str) >= 0) ||
                (p.name && p.brand.toLowerCase().search(str) >= 0)
            ))
        }

        if (!products) return;

        setDataProducts(searchProduct());

    }, [products, search]);

    const  getProducts = useCallback(async (category_l3_id = 0) => {

        setIsChanged(false)

        const response = await axios.get(`${process.env.REACT_APP_URL_API}/product/attribute/list/products`, {
            params: {
                category_l3_id: (category_l3_id ? category_l3_id : selectedL3)
            }
        })

        setProducts(
            await setAttributeColumns(response.data?.data)
        );
    }, [ selectedL3, setIsChanged ])

    useEffect(() => {
        setSearch('');
    }, [selectedL3]);

    useEffect(() => {
        if (!isChanged) return;

        getProducts()

    }, [isChanged, getProducts]);

    useEffect(() => {
        const searchProduct = () => {

            let str = search.replaceAll('\\', '').toLowerCase()
    
            return products.products.filter((p: any) => (
                p.product_id.toString().search(str) >= 0 ||
                (p.name && p.name.toLowerCase().search(str) >= 0 )||
                (p.name && p.brand.toLowerCase().search(str) >= 0)
            ))
        }

        if (!products) return;

        setDataProducts(searchProduct());
    }, [search, products]);

    

    async function onCategoryChange(value: any) {

        if (!value) return;

        setLoading(true)

        setProducts(null);

        setSelectedL3(value)

        getProducts(value);

        setLoading(false)
    }

   

    async function setAttributeColumns(data: any) {

        for (let i = 0; i < data.header.length; i++) {

            data.header[i]['sorter'] = (a: any, b: any) => {
                if (data.header[i].dataIndex[0] in a && data.header[i].dataIndex[0] in b) {
                    a[data.header[i].dataIndex[0]].toString().localeCompare(b[data.header[i].dataIndex[0]])
                } else if (data.header[i].dataIndex[0] in a) {
                    return -1;
                }
                return 1;
            };
        }

        return data;
    }

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Product attribute" />  
            <Divider>Attribute product list</Divider>
            <Row justify="center">
                <Col span={4}>
                    <Select
                        showSearch
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Select category"
                        onChange={onCategoryChange}
                        options={state.categoryL3L4.data}
                        filterOption={(input, option: any) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    />
                </Col>
            </Row>

            <Divider>Products</Divider>

            {products &&
                <Col span={12} style={{ padding: '20px', maxWidth: '100%', width: '100%' }}>
                    <Row>
                        <Col span={6} offset={9} style={{ marginBottom: '30px' }}>
                            <Input placeholder="Search.." onChange={(e) => setSearch(e.target.value)} />
                        </Col>
                    </Row>
                    <Table 
                        className={'table-attr-product'} 
                        id='attribute-product-list'
                        dataSource={dataProducts} columns={[{
                        title: 'Action', dataIndex: 'product_id', key: 'action', render: (text: any, record: any) => (
                            <Button onClick={() => handleProduct(record)}>
                                Edit
                            </Button>
                        )
                    },
                    { title: 'ID', dataIndex: 'product_id', key: 'product_id', sorter: (a: any, b: any) => (a.product_id > b.product_id ? 1 : -1) },
                    { title: 'Brand', dataIndex: 'brand', key: 'brand', sorter: (a: any, b: any) => a && b && a.brand.localeCompare(b.brand) },
                    { title: 'Name', dataIndex: 'name', key: 'name', sorter: (a: any, b: any) => a.name && b.name && a.name.localeCompare(b.name) },
                    { title: 'Not Empty', dataIndex: 'not_null', key: 'not_null', sorter: (a: any, b: any) => (a.not_null > b.not_null ? 1 : -1) },
                    ].concat(products.header)} />

                    <AttributeModal isShown={isShown} hide={toggle} product={product} data={data} change={change} />
                </Col>
            }

            {loading && <div className="loading-box">
                <Spin />
            </div>}
        </React.Fragment>
    )
}

export default AttributeProductList