import React, { useState } from "react";
import { AttributeType } from "../../types/AttributeType";
import { Button, Divider, Row, Select, Space, Typography } from "antd";
import showMessage from "../../../../shared/MessagesInfo/message";
import axios from "axios";

interface MergeAttributeProps {
    record: AttributeType;
    setAttributeList: React.Dispatch<React.SetStateAction<AttributeType[]>>;
    currentCountryName: string;
    getData: () => Promise<void>;
    attributeList: AttributeType[]; // old attribute overview page
    allAttributes?: {
        value: string;
        label: string;
    }[];
}

const MergeAttribute: React.FC<MergeAttributeProps> = (props) => {
    const [attributeForMerge, setAttributeForMerge] = useState<string | undefined>();
    const [mergeLoading, setMergeLoading] = useState(false);

    const mergeAttributes = async (id: number) => {
        setMergeLoading(true);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/merge-attribute`, {
                source: id,
                destination: attributeForMerge,
            });
            showMessage(data.message);
            props.getData();
            setAttributeForMerge(undefined);
        } catch (err) {
            console.log(err);
        }
    };

    const closeMergePopover = () => {
        props.setAttributeList((curr) => curr.map((a) => ({ ...a, mergePopover: false })));
    };

    return (
        <Row style={{ position: "relative", padding: "1rem" }}>
            <Typography.Text strong style={{ position: "absolute", left: "1rem", top: "-0.2rem" }}>
                {props.record[`name_${props.currentCountryName}` as keyof AttributeType]}
            </Typography.Text>
            <Button
                size="small"
                style={{ position: "absolute", right: "1rem", top: "-0.2rem" }}
                onClick={closeMergePopover}
            >
                Close
            </Button>
            <Divider style={{ margin: "0.7rem 0" }} />
            <Space.Compact>
                <Select
                    showSearch
                    style={{ width: 400 }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    value={attributeForMerge}
                    allowClear
                    filterOption={(input, option) =>
                        typeof option?.label === "string" &&
                        typeof input === "string" &&
                        (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
                    }
                    onChange={(e) => setAttributeForMerge(e)}
                    options={
                        props.allAttributes
                            ? props.allAttributes.filter((a) => a.value !== props.record.id.toString())
                            : props.attributeList
                                  .filter((a) => a.id !== props.record.id)
                                  .map((a) => ({
                                      value: a.id.toString(),
                                      label: a[`name_${props.currentCountryName}` as keyof typeof a],
                                  }))
                    }
                />
                <Button
                    size="small"
                    disabled={!attributeForMerge}
                    onClick={() => mergeAttributes(props.record.id)}
                    loading={mergeLoading}
                >
                    Merge
                </Button>
            </Space.Compact>
        </Row>
    );
};

export default MergeAttribute;
