import { Button, Col, Form, Modal, Row } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import React, { useState } from 'react'
import InputE from '../../../../shared/FormElements/Input'
import { ValueType } from '../../types/AttributeType'
import axios from 'axios'
import openNotification from '../../../../shared/MessagesInfo/WarningBox'
import { ArrowDownOutlined } from '@ant-design/icons'

interface AllowValueModalProps  {
    form: FormInstance
    isModalOpen: boolean
    handleOk: () => void 
    handleCancel: () => void 
    values:ValueType 
    saveNewAllowedValue: (e: EventListenerObject & {value_rs: string}) => Promise<void>
    countries: { name: string, value: number} []
}

const AllowValueModal:React.FC<AllowValueModalProps> = ({ isModalOpen, handleOk, handleCancel, values, saveNewAllowedValue, form, countries }) => {
    const [ translateLoading, setTranslateLoading ] = useState(false)

    
    const translateAttribute = async () => {
        if (form.getFieldValue('value_rs')?.trim()){
            setTranslateLoading(true)
            try {
                const { data } = await axios.get(`${ process.env.REACT_APP_URL_API }/prices/translator/translate?text=${ form.getFieldValue('value_rs').trim() }`)
                const obj: Record<string, string> = {}
                countries.forEach( c => {
                    if ( c.name !== 'rs' ) { 
                        obj[`value_${ c.name }`] = data.data[c.name]
                    }
                })
                form.setFieldsValue({
                    ...obj,
                    "value_en": data.data.en
                })
                setTranslateLoading(false)
            } catch ( err ) {
                console.log(err)
                setTranslateLoading(false)
            } 
        }
        else {
            openNotification('Field "value_rs" is required')
        }
    }

    return (
        <Modal 
            title="Allow new value" 
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            footer={false}
        >
            <Row justify='center'>
                <Col span={20}>
                    <p style={{ color:'darkred' }}>{ values.name }</p>
                    <Form form={form} onFinish={saveNewAllowedValue}>
                        {countries.map( c => 
                            <div style={{ height:'4rem' }}  key={c.name}>
                                <InputE
                                    label={`value_${ c.name }`} 
                                    name={`value_${ c.name }`}
                                    rules={[{ required: true, message: `Missing ${ `value_${ c.name }` }` }]}    
                                
                                />
                                {c.name === 'rs' &&  
                                <Button 
                                    style={{ position:"absolute", top:'4.5rem', right:'0rem' }} 
                                    shape='round' 
                                    type='primary'
                                    size='small'
                                    onClick={ translateAttribute }
                                    loading={ translateLoading  }
                                    icon={<ArrowDownOutlined />}
                                >
                                    Translate
                                </Button>}
                            </div>)}
                        <InputE
                            label={`value_en`} 
                            name={`value_en`}
                            rules={[{ required: true, message: `Missing value_en` }]}    
                        
                        />
                        <Button 
                            size='small' 
                            htmlType="submit" 
                            style={{ marginLeft:'78%' }}  
                            danger 
                        >
                            Allow Value
                        </Button>
                    </Form>
                </Col>
            </Row>
            
        </Modal>
    )
}

export default AllowValueModal