import axios from "axios";
import showMessage from "../../../shared/MessagesInfo/message";
import { UpdateType } from "../types/modals";

export const updateProduct = async (
    product_id: number | undefined,
    type: UpdateType,
    obj: any, //   ObjBrandType | ObjMpnType | ObjCategoryType | ObjNameType
    // setUpdateLoading: React.Dispatch<React.SetStateAction<boolean>>,
    currentCountryName?: string
) => {
    const baseUrl = process.env.REACT_APP_URL_API;

    // try {
    const { data } = await axios.post(`${baseUrl}/product/item/update`, {
        id: product_id,
        ...(type === "brand" && { brand_id: obj?.brand_id }), // object for brand
        ...(type === "mpn" && { mpn: obj.mpn }),
        ...(type === "category" && { category_l3_id: obj.category_l3_id }),
        ...(type === "name" && { [`name_${currentCountryName}`]: obj.name }),
    });
    showMessage(data.message);
    return data;
    // } catch (err) {
    //     console.log(err);
    // }
};
