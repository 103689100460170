import { ColumnsType } from "antd/lib/table";
import React from "react";
import { ProductPricesType } from "../types/types";
import { Button, Image, Popconfirm, Spin } from "antd";
import numberWithCommas from "../../../shared/utils/numberUtils";
import { CheckOutlined } from "@ant-design/icons";
import axios from "axios";
import showMessage from "../../../shared/MessagesInfo/message";
import openNotification from "../../../shared/MessagesInfo/WarningBox";

const useProductTableColumns = (
    setNewDataInTable: (text: string, key: "name" | "shop_name" | "mpn" | "image" | "sku" | "product_model", price_id?: number) => void,
    productPrices: ProductPricesType[],
    setProductPrices: React.Dispatch<React.SetStateAction<ProductPricesType[]>>
) => {
    const checkItem = (id: number) => {
        setProductPrices((curr) => curr.map((p) => (p.id === id ? { ...p, isChecked: !p.isChecked } : p)));
    };

    const detachPrices = async () => {
        try {
            const baseUrl = process.env.REACT_APP_URL_API;
            const { data } = await axios.post(`${baseUrl}/prices/detach-prices`, {
                price_ids: productPrices.filter((p) => p.isChecked).map((p) => p.id),
            });
            setProductPrices((curr) => curr.filter((p) => !p.isChecked));
            showMessage(data);
        } catch (err) {
            console.log(err);
        }
    };

    const columns: ColumnsType<ProductPricesType> = [
        {
            title: "Shop",
            dataIndex: "product_url",
            width: "200px",
            render: (text, record) => (
                <a
                    href={record.product_url}
                    onClick={() => window.open(text, "", "width=900,height=1080")}
                    className="shop-link"
                    style={{ color: "#01b0f5", textDecoration: "underline", fontWeight: "normal" }}
                    target="_blank"
                    rel="noreferrer"
                >
                    {record.shop_name}
                </a>
            ),
        },
        {
            title: "Image",
            dataIndex: "seller_image",
            align: "center" as "center",
            width: "60px",
            render: (text, record) => (
                <div style={{ position: "relative" }}>
                    <Image
                        src={text}
                        width={45}
                        alt="seller"
                        preview={false}
                        onClick={() => {
                            record.seller_image ? setNewDataInTable(record.seller_image, "image", record.id) : openNotification("No image source");
                        }}
                        className="dropdown-table-cell"
                    />
                    {record.isImgPriceToProduct && <Spin style={{ position: "absolute", top: "25%", left: "25%" }} />}
                </div>
            ),
        },
        {
            title: "Name",
            dataIndex: "product_name",
            render: (_, record) => (
                <span className="dropdown-table-cell" onClick={() => setNewDataInTable(record.product_name, "name")}>
                    {record.product_name}
                </span>
            ),
        },
        {
            title: "Mpn",
            dataIndex: "mpn",
            render: (_, record) => (
                <span className="dropdown-table-cell" onClick={() => setNewDataInTable(record.mpn, "mpn")}>
                    {record.mpn}
                </span>
            ),
        },
        {
            title: "Price",
            dataIndex: "price",
            align: "center" as "center",
            width: "200px",
            sorter: (a: ProductPricesType, b: ProductPricesType) => Number(a.price) - Number(b.price),
            filters: [
                {
                    text: "Active Prices",
                    value: 1,
                },
                {
                    text: "Inactive Prices",
                    value: 0,
                },
            ],
            onFilter: (value, record) => {
                return record.active_for_website === value;
            },
            render: (text, record) => <span style={{ fontWeight: record.active_for_website ? "bold" : "" }}>{numberWithCommas(String(text).replace(".", ","))} </span>,
        },
        {
            title: "Model",
            dataIndex: "product_model",
            width: "200px",
            render: (_, record) => (
                <span className="dropdown-table-cell" onClick={() => setNewDataInTable(record.product_model, "product_model")}>
                    {record.product_model}
                </span>
            ),
        },
        {
            title: "Sku",
            dataIndex: "sku",
            width: "200px",
            render: (_, record) => (
                <span className="dropdown-table-cell" onClick={() => setNewDataInTable(record.sku, "sku")}>
                    {record.sku}
                </span>
            ),
        },
        {
            title: (
                <Popconfirm placement="top" title={"Are you sure?"} onConfirm={detachPrices} okText="Yes" cancelText="No">
                    <Button danger size="small" disabled={!productPrices.find((p) => p.isChecked)}>
                        Detach
                    </Button>
                </Popconfirm>
            ),
            dataIndex: "id",
            align: "center" as "center",
            width: "90px",
            render: (_, record) => (
                <div
                    onClick={() => checkItem(record.id)}
                    style={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        padding: "10px",
                    }}
                >
                    {
                        <CheckOutlined
                            style={{
                                opacity: record.isChecked ? "1" : "0",
                            }}
                        />
                    }
                </div>
            ),
        },
    ];

    return columns;
};

export default useProductTableColumns;
