import { useState } from 'react';
import axios from "axios";

export const useModal = () => {
    const [isShown, setIsShown] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [product, setProduct] = useState<any>();
    const [data, setData] = useState<any>();
    const change = () => setIsChanged(true);
    const toggle = () => setIsShown(!isShown);

    async function handleProduct(product: any) {

        setProduct(product);

        const response = await axios.get(`${process.env.REACT_APP_URL_API}/product/attribute/product/list`, {
            params: {
                id: product.product_id
            }
        })

        setData(response.data?.data);

        setIsShown(true);
        setIsChanged(false);
    }
    return {
        isShown,
        toggle,
        product,
        handleProduct,
        data,
        isChanged,
        change,
        setIsChanged
    };
};