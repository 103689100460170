import React from 'react'
import { Row, Col, Divider, Select, Table, Button, Card, List, Input, Modal, Form, InputNumber, Checkbox, notification } from 'antd'
import { RootStore } from '../../../Store';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategoryL3 } from '../../../actions/category/categoryActions';
import axios from "axios";
import { EditOutlined } from '@ant-design/icons';
//import ListSort from '../../../shared/List/ListSort';
import { useParams } from 'react-router'
//import $ from 'jquery';
import '../style/AttributeCategory.css'
import HelmetTitle from '../../../shared/Head/HelmetTitle';

interface DataItem {
    id: number,
    value: string,
    attribute_id: number
};

interface Data {
    id: number,
    values: DataItem[],
    name: string | undefined
};

interface Attribute {
    id: number,
    min_value: any,
    max_value: any,
    type: string,
    category_l3_id: number,
    multiple: boolean,
    name: string,
    order: number,
    visible: any[]
};

const instanceAttribute = { id: 0, min_value: 0, max_value: 0, type: 'list', category_l3_id: 0, multiple: false, name: '', order: 0, visible: [] };

const AttributeCategory: React.FC = () => {

    const state = useSelector((state: RootStore) => state)
    const dispatch = useDispatch()
    const [selectedL3, setSelectedL3] = useState<any>()
    const [attributes, setAttributes] = useState<any>()
    const [data, setData] = useState<Data>()
    const [isShownValue, setisShownValue] = useState<boolean>(false) // box sa desne strane
    const { Option } = Select;
    const param: any = useParams()

    /* === value === */

    const [search, setSearch] = useState<string | null>(null);
    const [isModalVisibleEditValue, setIsModalVisibleEditValue] = useState(false);
    const [dataValue, setDataValue] = useState<DataItem | null>();
    const [inputValue, setInputValue] = useState<string | null>(null);

    /* === attribute === */

    const [types, setTypes] = useState<any[]>();
    const [visibles, setVisibles] = useState<any[]>();
    const [isModalVisibleAttribute, setIsModalVisibleAttribute] = useState(false);
    const [dataAttribute, setDataAttribute] = useState<Attribute>();
    const [form] = Form.useForm();

    /* === sort === */

    //const [isModalVisibleSort, setIsModalVisibleSort] = useState(false);
    const currentCountryName =  state.dataLs.country ? state.dataLs.country.currentCountry:"rs"
    const currentCountryId =  state.dataLs.country ? state.dataLs.country.countryId:1

    useEffect(() => {
        if (!param?.category_l3_id) return;

        setSelectedL3(param.category_l3_id)
    }, [ param.category_l3_id ]);

    useEffect(() => {
        dispatch(getAllCategoryL3(currentCountryId))
    }, [dispatch, currentCountryId])

    const getAttributes = useCallback(async (category_l3_id = 0) => {

        setAttributes([]);

        const response = await axios.get(`${process.env.REACT_APP_URL_API}/product/attribute/category/list`, {
            params: {
                id: (category_l3_id ? category_l3_id : selectedL3)
            }
        })

        setAttributes(response.data?.data.attribute);
        setTypes(response.data?.data.types)
        setVisibles(response.data?.data.visibles)
    }, [selectedL3])

    useEffect(() => {
        if (!selectedL3) return;

        getAttributes();

    }, [selectedL3, getAttributes]);

    async function onCategoryChange(value: number | undefined) {
        setSelectedL3(value);
    }



    /* ===== view attribute ====== */

    async function viewAttribute(param: any) {

        setSearch(null)
        setisShownValue(true);

        const response = await axios.get(`${process.env.REACT_APP_URL_API}/product/attribute/show`, {
            params: {
                id: param.id
            }
        });

        setData(response.data?.data)
    }

    const columnsAttribute = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            align: 'right' as 'right'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
        {
            title: 'Visible',
            key: 'visible',
            align: 'center' as 'center',
            render(text: any, record: Attribute) {
                return <Select defaultValue={record.visible} mode="multiple" key={'visible_select_' + record.id} style={{ width: '220px' }} placeholder="Visible mode" onChange={(value) => { record.visible = value; }}>
                    {visibles?.map((item: any, index: number) => {
                        return <Option value={item} key={'visible_select_' + record.id + '_' + index}>
                            {item}
                        </Option>;
                    })}
                </Select>
            }
        },
        {
            title: 'Multiple',
            key: 'multiple',
            align: 'center' as 'center',
            render(text: any, record: Attribute) {
                return <Checkbox defaultChecked={record.multiple} onChange={(e) => record.multiple = e.target.checked} />
            }
        },
        {
            title: 'Min value',
            dataIndex: 'min_value',
            key: 'min_value',
            align: 'center' as 'center'

        },
        {
            title: 'Max value',
            dataIndex: 'max_value',
            key: 'max_value',
            align: 'center' as 'center'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center' as 'center'
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center' as 'center',
            render(text: any, record: Attribute) {
                return <div>
                    <Button className={'primary'} size="small" onClick={() => showModalAttribute(record)}>
                        Edit
                    </Button>
                    <Button className={'primary'} size="small" onClick={() => viewAttribute(record)}>
                        Values
                    </Button>
                </div>
            }
        }
    ]

    /* ===== modal config ====== */

    const handleCancel = () => {
        setIsModalVisibleEditValue(false);
        setIsModalVisibleAttribute(false);
        //setIsModalVisibleSort(false);

        setDataAttribute(instanceAttribute)
        form.setFieldsValue(instanceAttribute)
    };

    /* ===== modal value ====== */

    const showModalEditValue = (data: DataItem) => {
        setIsModalVisibleEditValue(true);
        setDataValue(data);
        setInputValue(data.value);
    };

    async function handleOkEditValue() {

        let result = await saveValue();

        if (!result) return false;

        setIsModalVisibleEditValue(false);
    };

    async function saveValue() {

        let check = true;

        await axios.post(`${process.env.REACT_APP_URL_API}/product/attribute/value/upsert`, {
            attribute_id: dataValue?.attribute_id,
            attribute_allowed_value_id: dataValue?.id,
            value: inputValue,
        }).catch(error => {
            notification.error({
                message: error.response.data.message,
                description: error.response.data.errors[0],
            });

            check = false;
        })

        if (!check) return false;

        await viewAttribute({ id: data?.id })

        return true;
    }

    /* ===== modal attribute ====== */

    const showModalAttribute = (data: Attribute) => {
        setDataAttribute({ ...data })
        setIsModalVisibleAttribute(true);
        form.setFieldsValue({ ...data });

        form.setFieldsValue({ multiple: false })
    };

    async function handleOkAttribute() {

        let result = await saveAttribute();

        if (!result) return;
        handleCancel();

        setIsModalVisibleAttribute(false);
    };

    async function saveAttribute() {

        let check = true;

        var request: any = {
            id: dataAttribute?.id,
            name: dataAttribute?.name,
            min_value: dataAttribute?.min_value,
            max_value: dataAttribute?.max_value,
            type: dataAttribute?.type,
            multiple: dataAttribute?.multiple
        }

        if (dataAttribute?.category_l3_id === 0) {

            const url = `${process.env.REACT_APP_URL_API}/product/attribute/create`;
            request['category_l3_id'] = selectedL3;
            await axios.post(url, request).catch(error => {
                notification.error({
                    message: error.response.data.message,
                    description: error.response.data.errors[0],
                });

                check = false;
            })

        } else {

            const url = `${process.env.REACT_APP_URL_API}/product/attribute/update`;
            await axios.put(url, request).catch(error => {

                notification.error({
                    message: error.response.data.message,
                    description: error.response.data.errors[0],
                });

                check = false;
            })
        }

        if (!check) return false;

        await getAttributes()

        return true;
    }

    /* ===== modal sort ====== */

    // const showModalSort = () => {
    //     setIsModalVisibleSort(true);
    // };

    // async function handleOkSort() {

    //     var order: any = [];

    //     $('.ant-list-items .ant-list-item').each(function (index, item) {
    //         order.push({ order: index, id: $(item).data('id') })
    //     })

    //     await getAttributes();

    //     setIsModalVisibleSort(false);
    // }

    /* ===== attribute save changes ===== */

    async function saveChangesAttribute() {

        var array = [];

        for (var i = 0; i < attributes.length; i++) {
            array.push({
                id: attributes[i].id,
                visible: attributes[i].visible ? attributes[i].visible : null,
                multiple: attributes[i].multiple
            })
        }

        await axios.post(`${process.env.REACT_APP_URL_API}/product/attribute/update-mass`, {
            category_l3_id: selectedL3,
            attributes: array
        });

        await getAttributes();
    }

    /* ===== render ====== */

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Category attributes" />  
            <Divider>Select category</Divider>
            <Row justify="center">
                <Col span={4}>
                    {state?.categoriesL3 &&
                        <Select
                            style={{ width: '100%' }}
                            key={'select_category'}
                            showSearch
                            allowClear
                            placeholder="Select category"
                            onChange={onCategoryChange}
                            filterOption={(input, option: any) =>
                                option.children !== null && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            defaultValue={param?.category_l3_id}
                        >
                            {
                                state.categoriesL3.data && state.categoriesL3.data.sort((a: any, b: any) => (a[`name_${ currentCountryName }`] > b[`name_${ currentCountryName }`]) ? 1 : ((b[`name_${ currentCountryName }`] > a[`name_${ currentCountryName }`]) ? -1 : 0)).map((option: any) =>
                                    option && option.id !== 1 && <Option key={option.id} value={option.id}>{option[`id`] + ': ' + option[`name_${ currentCountryName }`]}</Option>
                                )
                            }
                        </Select>
                    }
                </Col>
                {selectedL3 && <Col span={3} offset={1}>
                    <Button className={'primary'} danger onClick={() => showModalAttribute(instanceAttribute)}>
                        Add attribute
                    </Button>
                </Col>}
                {/*selectedL3 && attributes && attributes.length > 0 && <Col span={3}>
                    <Button type={'primary'} className={'primary'} onClick={() => showModalSort()}>
                        Sort attribute
                    </Button>
                </Col>*/}
            </Row>
            <Divider>Products</Divider>
            {attributes && <Row>
                <Col span={isShownValue ? 15 : 21} offset={1}>
                    <Table 
                        columns={columnsAttribute} 
                        dataSource={attributes} 
                        size="small" 
                        pagination={{ defaultPageSize: 15 }}
                        id='attribute-cateogry-table'
                        footer={() => <div className="text-center">
                            <Button className={'primary'} danger onClick={saveChangesAttribute}>
                                Save
                            </Button>
                        </div>} />
                </Col>
                {(isShownValue && data) && <Col span={7} style={{ paddingLeft: '15px' }}>
                    <Input size="large" placeholder="Search attribute values" onChange={(e) => setSearch(e.target.value)} value={search ? search : ''} />
                    <Card title={data.name} size="small" style={{ width: '100%', marginTop: '5px' }} className={'card-scroll'}>
                        <List
                            dataSource={data.values.filter(d => (d.value && d!.value.toUpperCase().indexOf((search != null ? search.toUpperCase() : '')) !== -1))}
                            header={<div>
                                <Button className={'primary'} danger onClick={() => showModalEditValue({ id: 0, value: '', attribute_id: data.id })}>
                                    Add new value
                                </Button>
                            </div>}
                            renderItem={item => (
                                <List.Item
                                    key={item.id}
                                >
                                    <Button className={'primary ant-icon'} style={{ marginRight: '5px' }} size="small" danger onClick={() => showModalEditValue(item)}>
                                        <EditOutlined />
                                    </Button>
                                    {item.value}
                                </List.Item>
                            )}
                        />
                    </Card>
                    <Modal title={data.name + ': ' + (dataValue?.value ? dataValue?.value : 'Add new value')} visible={isModalVisibleEditValue} onOk={handleOkEditValue} onCancel={handleCancel}>
                        {dataValue && <Row>
                            <Col span={24}>
                                <Input placeholder="Input attribute value" value={inputValue ? inputValue : ''} onChange={(e) => setInputValue(e.target.value)} />
                            </Col>
                        </Row>}
                    </Modal>
                </Col>}
                <Modal title={'Add new attribute'} open={isModalVisibleAttribute} onOk={handleOkAttribute} onCancel={handleCancel}>
                    {dataAttribute && types &&
                        <Form
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            autoComplete="on"
                            form={form}
                        >
                            <Form.Item label="Name" name="name">
                                <Input value={dataAttribute.name} onChange={(e) => (dataAttribute.name = e.target.value)} />
                            </Form.Item>
                            <Form.Item label="Type" name="type">
                                <Select value={dataAttribute.type} onChange={(value) => (dataAttribute.type = value)}>
                                    {types.map(item => (
                                        <Select.Option key={'type_' + item.id} value={item.id}>
                                            {item.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label="Min value" name="min_value">
                                <InputNumber value={dataAttribute?.min_value} onChange={(value) => (dataAttribute.min_value = value)} />
                            </Form.Item>
                            <Form.Item label="Max value" name="max_value">
                                <InputNumber value={dataAttribute?.max_value} onChange={(value) => (dataAttribute.max_value = value)} />
                            </Form.Item>
                            {/* <hr />
                            <Checkbox defaultChecked={dataAttribute.multiple} checked={dataAttribute.multiple} value={1} onChange={(e) => {
                                dataAttribute.multiple = e.target.checked;
                                form.setFieldsValue({ multiple: e.target.checked })
                            }}>
                                Multiple
                            </Checkbox> */}
                        </Form>
                    }
                </Modal>
                {/* <Modal title={'Sort attribute'} visible={isModalVisibleSort} onOk={handleOkSort} onCancel={handleCancel}>

                    <div className={`ant-list ant-list-sm ant-list-split ant-list-bordered ant-list-something-after-last-item`}>
                        <div className={'ant-spin-container'}>
                            <div className={'ant-list-items'}>
                                <ListSort dragClassName="list-drag-selected" >
                                    {attributes.sort((a: Attribute, b: Attribute) => (a.order > b.order) ? 1 : -1).map((item: Attribute, index: number) => {
                                        return (
                                            <div key={index} className={`ant-list-item move`} data-id={item.id}>
                                                {(index + 1) + '. ' + item.name}
                                            </div>
                                        );
                                    })}
                                </ListSort>
                            </div>
                        </div>
                    </div>

                </Modal> */}
            </Row>}
        </React.Fragment>
    )
}

export default AttributeCategory