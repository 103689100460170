import { DeleteTwoTone } from "@ant-design/icons";
import { Popconfirm, Table } from "antd";
import axios from "axios";
import React, { /*useCallback,*/ useRef } from "react";
import showMessage from "../../../../shared/MessagesInfo/message";
import { AttirbuteDefRulesType, ValueType } from "../../types/AttributeType";
import AttributeDefItem from "./AttributeDefItem";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

let isDrag = false;

interface AttributeDefinitionProps {
    attirbuteDefRules: AttirbuteDefRulesType[] | null;
    setAttributeDefRules: React.Dispatch<React.SetStateAction<AttirbuteDefRulesType[] | null>>;
    getAttributeDefRules: (obj: { shop_id: number | null; key: string; l3_id: number | undefined }) => Promise<void>;
    values: ValueType;
    activeTabKey: number | null;
    selectedCategory: {
        id: number;
        name: string;
    };
}

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    index: number;
    moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = "DraggableBodyRow";

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }: DraggableBodyRowProps) => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor: any) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
            };
        },
        drop: (item: { index: number }) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    return <tr ref={ref} className={`${className}${isOver ? dropClassName : ""}`} style={{ cursor: isDrag ? "default" : "move", ...style }} {...restProps} />;
};

const AttributeDefinition: React.FC<AttributeDefinitionProps> = ({ attirbuteDefRules, setAttributeDefRules, getAttributeDefRules, values, activeTabKey, selectedCategory }) => {
    const deleteRuleDef = async (id: number) => {
        try {
            //console.log(attirbuteDefRules)
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/prices/attribute-value-cleaning/delete-rule-definition?definition_id=${id}`);
            showMessage(data.message);
            values &&
                getAttributeDefRules({
                    shop_id: activeTabKey,
                    key: values?.name,
                    l3_id: selectedCategory?.id,
                });
        } catch (err) {
            console.log(err);
        }
    };

    const changeOrder = async (dragIndex: number, hoverIndex: number, attirbuteDefRules: AttirbuteDefRulesType[]) => {
        const dragRow = attirbuteDefRules[dragIndex]; //this is a element
        const objToSend = update(attirbuteDefRules, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRow],
            ],
        });

        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attribute-value-cleaning/update-definition-order`, {
                order: { order: objToSend.map((a, i) => ({ order: i + 1, definition_id: a.definition_id })) },
            });
            console.log(data);

            setAttributeDefRules(
                update(attirbuteDefRules, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                })
            );
        } catch (err) {
            console.log(err);
        }
    };

    const moveRow = (dragIndex: number, hoverIndex: number) => {
        if (attirbuteDefRules) {
            console.log("test drag");
            changeOrder(dragIndex, hoverIndex, attirbuteDefRules);

            // console.log( update(attirbuteDefRules, {
            //   $splice: [
            //       [dragIndex, 1],
            //       [hoverIndex, 0, dragRow],
            //   ],
            //   }))
        }
    };

    if (attirbuteDefRules?.length === 0) return <p></p>;

    return (
        <DndProvider backend={HTML5Backend}>
            <Table
                columns={[
                    {
                        title: "",
                        dataIndex: "rule_name",
                        width: "44%",
                    },
                    {
                        title: "",
                        dataIndex: "rule_name",
                        width: "44%",
                        render: (_, record) => (typeof record.parameter_values !== "string" ? record.parameter_values.map((r: any) => <AttributeDefItem rule={r} key={r.parameter_id} />) : null),
                    },
                    {
                        title: "",
                        dataIndex: "id",
                        width: "10%",
                        render: (_, record) => (
                            <Popconfirm
                                title="Delete the definition"
                                description="Are you sure to delete this definition?"
                                onConfirm={() => deleteRuleDef(record.definition_id)}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                                style={{ zIndex: 100 }}
                            >
                                <DeleteTwoTone style={{ cursor: "pointer", marginRight: "3px" }} />
                            </Popconfirm>
                        ),
                    },
                ]}
                dataSource={attirbuteDefRules ? attirbuteDefRules : []}
                size="small"
                pagination={false}
                style={{ margin: "6px 0" }}
                components={{
                    body: {
                        row: DraggableBodyRow,
                    },
                }}
                onRow={(_, index) => {
                    const attr = {
                        index,
                        moveRow,
                    };
                    return attr as React.HTMLAttributes<typeof attr>;
                }}
            />
        </DndProvider>
    );
};

export default AttributeDefinition;
