import React from "react";
import { List, Row, Col, Divider, Select, Modal, Button, Form, Input, Image, /*PageHeader*/ } from 'antd';
import axios from "axios";
export interface ModalProps {
    isShown: boolean,
    hide: () => void,
    product: any,
    data: any,
    change: () => void,
}

interface Shop {
    product_id: number,
    product_url: string,
    name: string,
    logo_url: string | null
}

class AttributeModal extends React.Component<ModalProps> {

    state = { changes: 0, disabled: false, showOther: false, iframe: false, width: 0, height: 0, shop_site_name: '', shop_site_url: '' };
    array: any = [];
    debug: boolean = false;

    useComponentWillMount() {
        this.setState({
            changes: 0
        })
    }

    componentDidMount() {
        this.getDimensions();

        //add dimensions listener for window resizing
        window.addEventListener('resize', this.getDimensions);
    }

    //remove listener on page exit
    componentWillUnmount() {
        window.removeEventListener('resize', this.getDimensions);
    }

    getDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    toggleAttr = () => {
        this.setState({ showOther: !this.state.showOther })
    }

    handleClose = () => {

        this.props.hide();

        this.setState({ showOther: false, iframe: false, shop_site_url: '', shop_site_name: '' });
    }

    handleOk = () => {

        this.setState({ disabled: true });

        let attributes = [];

        for (var i = 0; i < this.array.attributes.length; i++) {

            if (this.array.attributes[i].value_id === -1 || (Array.isArray(this.array.attributes[i].value_id) && this.array.attributes[i].value_id.includes(-1))) {
                alert('Podaci nisu validni!');
                return;
            }

            if (!this.array.attributes[i].value_id.length) continue;

            attributes.push({
                attribute_id: this.array.attributes[i].id,
                attribute_allowed_value_id: this.array.attributes[i].value_id,
                product_id: this.props.product.product_id
            })
        }

        axios.post(`${process.env.REACT_APP_URL_API}/product/attribute/store-mass`, {
            product_id: this.props.product.product_id,
            attributes: attributes
        }).then(response => {

            this.setState({ disabled: false });

            this.props.change();
            this.props.hide();

        }).catch((error: any) => {

            this.setState({ disabled: false });

            alert(error)
        })

        this.setState({ showOther: false });
    }

    async addNewValue(value: string, attribute_id: number, id: number) {

        const response = await axios.post(`${process.env.REACT_APP_URL_API}/product/attribute/value/upsert`, {
            //${process.env.REACT_APP_URL_API}/product/attribute/list/products
            product_id: this.props.product?.product_id,
            attribute_id: attribute_id,
            value: value
        })

        if (!response.data.status) {
            alert('Doslo je do greske!');
            return;
        }

        this.array.attributes[id].values.push(response.data.data);

        this.forceUpdate();

        this.setState({ changes: this.state.changes + 1 })

        this.array.attributes[id].value_id = response.data.data.id;
        this.array.attributes[id].value = response.data.data.value;

        this.setState({ changes: this.state.changes + 1 })

        this.forceUpdate();
    }

    openIFrame(name: string, link: string) {

        link = link.replace('www.', '')

        let input = document.createElement("input");
        input.value = link;
        document.body.appendChild(input);
        input.select();

        document.execCommand("copy");
        document.body.removeChild(input);

        this.setState({
            iframe: true,
            shop_site_url: link,
            shop_site_name: name
        })
    }

    findID = (id: number) => {

        for (let i = 0; i < this.array.attributes.length; i++) {
            if (this.array.attributes[i].id === id) return i;
        }

        return 0
    }

    render() {
        const productDetail = [
            'Brand: ' + this.props.product?.brand,
            'Name: ' + this.array?.name,
            'ID: ' + this.array?.id,
            'Category: ' + this.array?.category_name
        ];

        this.array = this.props.data;

        const { Option } = Select

        return (
            <>
                <Modal
                    title={this.props.product?.product_id + ': ' + this.props.product?.brand + ' ' + this.props.product?.name}
                    visible={this.props.isShown}
                    onOk={this.handleOk}
                    onCancel={this.handleClose}
                    okButtonProps={{ disabled: this.state.disabled }}
                    destroyOnClose={true}
                    style={{ top: 20 }}
                    footer={[
                        <Button key="showHide" onClick={this.toggleAttr}>
                            {!this.state.showOther ? 'Show other attributes' : 'Hide other attributes'}
                        </Button>,
                        <Button
                            key="link"
                            href={'attribute-category/' + this.array?.category_l3_id}
                            type="primary"
                            target="add_attribute"
                        >
                            Add new attribute
                        </Button>,
                        <Button key="back" onClick={this.props.hide}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" danger loading={this.state.disabled} onClick={this.handleOk}>
                            Save
                        </Button>
                    ]}
                    className={(this.state.iframe ? 'modal-fullscreen' : 'modal-fullscreen-inputs')}
                >
                    <Row>
                        {this.state.iframe && this.state.shop_site_url && <Col span={this.state.width < 1340 ? 16 : 18}>
                            {/* <PageHeader
                                className="site-page-header"
                                onBack={() => this.setState({ iframe: false })}
                                title={this.state.shop_site_name}
                                subTitle={this.state.shop_site_url}
                            /> */}
                            <div className="modelFullScreenFrame">
                                <iframe src={this.state.shop_site_url} title={this.state.shop_site_name}></iframe>
                            </div>
                        </Col>}
                        <Col span={this.state.iframe ? (this.state.width < 1340 ? 8 : 6) : 24}>
                            <div className={'table-attribute-product-label'}>
                                <Row justify="center">
                                    <Col span={8} style={{ textAlign: 'center' }}>
                                        <Image
                                            width={'100%'}
                                            src={'https://img.ep-cdn.com/images/500/500/' + this.array?.default_image_url + '.webp'}
                                        />
                                    </Col>
                                    <Col span={16}>
                                        <List
                                            size="small"
                                            bordered
                                            dataSource={productDetail}
                                            renderItem={item => <List.Item>{item}</List.Item>}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Divider>Links</Divider>
                                        <Row>
                                            {this.array?.links.map((item: Shop, index:number) => {
                                                return <Col span={8} key={`${item.name}${index}`}>
                                                    <Button type="link" target={'view_shop'} onClick={() => this.openIFrame(item.name, item.product_url)}>
                                                        {item.name}
                                                    </Button>
                                                </Col>
                                            })}

                                        </Row>
                                        <Divider>Attributes</Divider>
                                        <Form
                                            name="edit-product-attribute-form"
                                            labelCol={{ span: 6 }}
                                            onFinish={() => this.handleOk}
                                            key="form-attribute"
                                        //onFinishFailed={onFinishFailed}
                                        >
                                            {[true, false].map((check: boolean, numberCheck: number) => (
                                                <Row key={numberCheck}>

                                                    {!check && this.state.showOther && <Divider>Other attributes</Divider>}
                                                    {this.array && (numberCheck === 0 || (numberCheck === 1 && !check && this.state.showOther)) && this.array?.attributes.filter((a: any) => (this.array.used.includes(parseInt(a.id)) === check)).map((attribute: any, id: any) => <Col span={24} key={numberCheck + '-' + id + '_col_row'}>


                                                        <Form.Item
                                                            label={(this.debug ? (attribute.id + ': ') : '') + attribute.name}
                                                            labelCol={{ span: 9 }}
                                                            key={numberCheck + '-item_' + attribute.id}
                                                        >
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                filterOption={(input:any, option:any) =>
                                                                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                }
                                                                mode={'multiple'}
                                                                style={{ width: '100%' }}
                                                                placeholder={"Select " + attribute.name}
                                                                key={numberCheck + '-' + attribute.id + '_attr_select_' + id}
                                                                defaultValue={attribute.value_id}
                                                                onChange={(value) => {

                                                                    this.array.attributes[this.findID(attribute.id)].value_id = value;

                                                                    this.setState({
                                                                        changes: this.state.changes + 1,
                                                                        disabled: value === -1
                                                                    })
                                                                }}
                                                            >
                                                                {attribute.values && attribute.values.sort().map((option: any, index: any) =>
                                                                    <Option value={option.id} key={numberCheck + '-option_' + attribute.id + '_' + option.id + '_' + index} disabled={
                                                                        !attribute.multiple && this.array.attributes[this.findID(attribute.id)].value_id.length >= 1 && !this.array.attributes[this.findID(attribute.id)].value_id.includes(option.id)
                                                                    }>
                                                                        {(this.debug ? (option.id + ': ') : '') + option.value}
                                                                    </Option>
                                                                )}
                                                                {/* <Option value="-1">
                                                                Dodati novu vrednost
                                                            </Option> */}
                                                            </Select>

                                                            {this.array.attributes[id].value_id === '-1' &&
                                                                <div style={{ width: 'calc(100% - 15px)', marginTop: '2px' }}>
                                                                    <Input key={numberCheck + '-input_' + attribute.id + '_value'} placeholder={"Unesite " + attribute.name} style={{ marginBottom: '2px' }} onChange={
                                                                        (e) => {
                                                                            this.array.attributes[id].text = e.target.value
                                                                        }} />
                                                                    <Button type="primary" block onClick={
                                                                        () => this.addNewValue(this.array.attributes[id].text, this.array.attributes[id].id, id)
                                                                    }>
                                                                        Sačuvaj novu vrednost
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </Form.Item>
                                                    </Col>)}
                                                </Row>))}
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Modal>
            </>
        );
    }
}

export default AttributeModal