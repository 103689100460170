import { AttributeType } from "../../types/AttributeType";
import React, { useContext, useMemo, useState } from "react";
import { HolderOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import type { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Checkbox, Col, Empty, FormInstance, Input, Popconfirm, Popover, Row, Select, Spin, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { GroupType } from "../../pages/AttributeOverview";
import axios from "axios";
import showMessage from "../../../../shared/MessagesInfo/message";
import MergeAttribute from "./MergeAttribute";
import { useSearchState } from "../../../../shared/hooks/useSearchState";

interface AttributeOverviewTableType {
    setSelectedAttribute: React.Dispatch<
        React.SetStateAction<
            | {
                  name: string;
                  id: string;
              }
            | undefined
        >
    >;
    attributeList: AttributeType[];
    setAttributeList: React.Dispatch<React.SetStateAction<AttributeType[]>>;
    currentCountryName: string;
    groups: GroupType[];
    setGroups: React.Dispatch<React.SetStateAction<GroupType[]>>;
    selectedCategory: {
        id: number;
        name: string;
    } | null;
    loadingAttributes: boolean;
    getAttributeList: () => Promise<void>;
    setTranslationModal: React.Dispatch<
        React.SetStateAction<{
            open: boolean;
            type: "attribute" | "value" | "";
        }>
    >;
    form: FormInstance<any>;
    allAttributes: {
        value: string;
        label: string;
    }[];
}

interface RowContextProps {
    setActivatorNodeRef?: (element: HTMLElement | null) => void;
    listeners?: SyntheticListenerMap;
}

const RowContext = React.createContext<RowContextProps>({});

const DragHandle: React.FC = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return <Button type="text" size="small" icon={<HolderOutlined />} style={{ cursor: "move" }} ref={setActivatorNodeRef} {...listeners} />;
};

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
}

const RowTable: React.FC<RowProps> = (props) => {
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
        id: props["data-row-key"],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    const contextValue = useMemo<RowContextProps>(() => ({ setActivatorNodeRef, listeners }), [setActivatorNodeRef, listeners]);

    return (
        <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
        </RowContext.Provider>
    );
};

const AttributeOverviewTable = (props: AttributeOverviewTableType) => {
    // const [dataSource, setDataSource] = React.useState<DataType[]>([]);
    const {
        attributeList,
        setAttributeList,
        currentCountryName,
        groups,
        selectedCategory,
        loadingAttributes,

        getAttributeList,
        setSelectedAttribute,
        setTranslationModal,
        form,
        allAttributes,
    } = props;
    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setAttributeList((prevState) => {
                const activeIndex = prevState.findIndex((record) => record.key === active?.id);
                const overIndex = prevState.findIndex((record) => record.key === over?.id);
                return arrayMove(prevState, activeIndex, overIndex);
            });
        }
    };

    const showValues = (record: AttributeType) => {
        setSelectedAttribute({ id: record.key, name: record[`name_rs` as keyof typeof record]?.toString() ?? "" });
    };

    const [attributeToGroup, setAttributeToGroup] = useState<number | undefined>();

    const assignAttributeToGroup = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>, record: AttributeType) => {
        const obj = {
            attribute_id: record.id,
            group_id: e,
        };
        setAttributeToGroup(record.id);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/assign-attribute-to-group`, obj);
            setAttributeList((curr) => curr.filter((a) => a.id !== record.id));
            setAttributeToGroup(undefined);
            showMessage(data);
        } catch (err) {
            console.log(err);
        }
    };

    const openTranslationModal = (record: AttributeType, type: string) => {
        setTranslationModal({ open: true, type: "attribute" });
        form.setFieldsValue(record);
    };

    const changeAttributeValue = (id: number, type: string, value: string | number | boolean) => {
        setAttributeList((curr) =>
            curr.map((data) =>
                data.id === id
                    ? {
                          ...data,
                          [type]: type === "show_filter" || type === "show_specification" ? Number(value) : value,
                          isChanged: true,
                      }
                    : data
            )
        );
    };

    const handleTypeChange = (record: AttributeType, type: string, isType: boolean) => {
        setAttributeList((curr) => curr.map((a) => (a.id === record.id ? { ...a, [isType ? `type` : `sort_type`]: type, isChanged: true } : a)));
    };

    const openPopoverForMerge = (id: number) => {
        setAttributeList((curr) => curr.map((a) => (a.id === id ? { ...a, mergePopover: true } : { ...a, mergePopover: false })));
    };

    const deleteAttribute = async (record: AttributeType) => {
        //console.log(record)
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_URL_API}/prices/attributes/delete-attribute?id=${record.id}`);
            setAttributeList((curr) => curr.filter((d) => d.id !== record.id));
            showMessage(data.message);
            //console.log(data)
        } catch (err) {
            console.log(err);
        }
    };

    const saveChanges = async (record: AttributeType) => {
        delete record[`category_name`];
        delete record[`category_l3_id`];
        delete record[`isMergeValues`];
        delete record[`order`];
        try {
            const { data } = await axios.put(`${process.env.REACT_APP_URL_API}/prices/attributes/update-attribute`, record);
            showMessage(data.message);
            setAttributeList((curr) => curr.map((a) => (a.id === record.id ? { ...a, isChanged: false } : a)));
        } catch (err) {
            console.log(err);
        }
    };

    const { getColumnSearchProps } = useSearchState(attributeList);

    const sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || "").localeCompare(b || "") : a - b);

    const columns: ColumnsType<AttributeType> = [
        { key: "sort", align: "center", width: 80, render: () => <DragHandle /> },
        {
            title: "Order",
            dataIndex: "order",
            width: 70,
            align: "center" as "center",
            sorter: (a: AttributeType, b: AttributeType) => sorter(a.order, b.order),
        },
        {
            title: "Name",
            dataIndex: `name_${currentCountryName}`,
            ...getColumnSearchProps(`name_${currentCountryName}`),
            sorter: (a: AttributeType, b: AttributeType) => sorter(a[`name_${currentCountryName}` as keyof AttributeType], b[`name_${currentCountryName}` as keyof AttributeType]),
            render: (text, record) => (
                <div className="attribute-overview-name-cell" onClick={() => showValues(record)}>
                    {text}
                </div>
            ),
        },
        {
            dataIndex: "id",
            width: 100,
            align: "center" as "center",
            render: (text, record) => (
                <Button size="small" onClick={() => openTranslationModal(record, "translation")}>
                    Translate
                </Button>
            ),
        },
        {
            title: "Min/Max",
            dataIndex: "value",
            width: 120,
            align: "center" as "center",
            render: (text, record) => (
                <>
                    <Row>
                        <Col span={24}>
                            {/* <span style={{ width: "25px" }}>Min:</span> */}
                            <Input
                                size="small"
                                style={{ width: "94px" }}
                                defaultValue={record.min_value === "0" ? "" : record.min_value}
                                type="number"
                                placeholder={record.min_value === "0" ? "0" : record.min_value}
                                onBlur={(e) => changeAttributeValue(record.id, "min_value", e.target.value)}
                                disabled={record.type === "range" ? false : true}
                            />
                        </Col>
                        <Col span={24}>
                            {/* <span style={{ width: "25px" }}>Max:</span> */}
                            <Input
                                size="small"
                                style={{ marginTop: "3px", width: "94px" }}
                                defaultValue={record.max_value === "0" ? "" : record.max_value}
                                placeholder={record.max_value === "0" ? "0" : record.max_value}
                                type="number"
                                onBlur={(e) => changeAttributeValue(record.id, "max_value", e.target.value)}
                                disabled={record.type === "range" ? false : true}
                            />
                        </Col>
                    </Row>
                </>
            ),
        },
        {
            title: "Type",
            dataIndex: "type",
            align: "center" as "center",
            width: 150,
            sorter: (a: AttributeType, b: AttributeType) => sorter(a.type, b.type),
            render: (_, record) => (
                <Select
                    className="att-overview-select-type"
                    defaultValue={record.type}
                    style={{
                        width: "120px",
                        padding: "1px",
                        border: `1px solid ${record.type === "list" ? "#ff347f" : "lightgray"}`,
                        borderRadius: "3px",
                    }}
                    size="small"
                    onChange={(e) => handleTypeChange(record, e, true)}
                >
                    <Select.Option key="select" value="select">
                        Select
                    </Select.Option>
                    <Select.Option key="multiselect" value="multiselect">
                        Multiselect
                    </Select.Option>
                    <Select.Option key="range" value="range">
                        Range
                    </Select.Option>
                    <Select.Option key="yesno" value="yesno">
                        YesNo
                    </Select.Option>
                </Select>
            ),
        },
        {
            title: "Sort",
            dataIndex: "sort_type",
            align: "center" as "center",
            width: 150,
            render: (_, record) => (
                <Select
                    className="att-overview-select-type"
                    defaultValue={record.sort_type}
                    style={{
                        width: "120px",
                        padding: "1px",
                        border: `1px solid ${record.sort_type === "list" ? "#ff347f" : "lightgray"}`,
                        borderRadius: "3px",
                    }}
                    size="small"
                    onChange={(e) => handleTypeChange(record, e, false)}
                >
                    <Select.Option key="numerical">Numerical</Select.Option>
                    <Select.Option key="alphabetical">Alphabetical</Select.Option>
                </Select>
            ),
        },
        {
            title: "Values",
            dataIndex: "count",
            width: 80,
            align: "center" as "center",
            sorter: (a: AttributeType, b: AttributeType) => sorter(a.count, b.count),
        },
        {
            title: "Filter",
            dataIndex: "",
            align: "center" as "center",
            width: 80,
            sorter: (a: AttributeType, b: AttributeType) => sorter(a.show_filter, b.show_filter),
            render: (_, record) => (
                <Checkbox checked={record.show_filter === 0 ? false : true} onChange={(e) => changeAttributeValue(record.id, "show_filter", e.target.checked ? 1 : 0)}>
                    <span style={{ color: "#005792" }}>Filter</span>
                </Checkbox>
            ),
        },
        {
            title: "Specification",
            dataIndex: "",
            align: "center" as "center",
            width: 125,
            sorter: (a: AttributeType, b: AttributeType) => sorter(a.show_specification, b.show_specification),
            render: (_, record) => (
                <Checkbox checked={record.show_specification === 0 ? false : true} onChange={(e) => changeAttributeValue(record.id, "show_specification", e.target.checked ? 1 : 0)}>
                    <span style={{ color: "#005792" }}>Specification</span>
                </Checkbox>
            ),
        },
        {
            title: "Primary",
            dataIndex: "primary",
            align: "center" as "center",
            width: 90,
            sorter: (a: AttributeType, b: AttributeType) => sorter(a.primary, b.primary),
            render: (_, record) => <Checkbox checked={record.primary} onChange={(e) => changeAttributeValue(record.id, "primary", e.target.checked)} />,
        },
        {
            dataIndex: "id",
            align: "center" as "center",
            width: 160,
            render: (text, record) => (
                <Select
                    placeholder="Change group"
                    size="small"
                    style={{ width: "94%" }}
                    onChange={(e) => assignAttributeToGroup(e, record)}
                    options={groups
                        .filter((g) => {
                            if (g.id === record.group_id) {
                                return false;
                            } else if (g.id === 0 && record.group_id === null) {
                                return false;
                            } else {
                                return true;
                            }
                        })
                        .map((g) => ({ value: g.id, label: g[`name_${currentCountryName}`] }))}
                />
            ),
        },
        {
            title: "",
            dataIndex: "id",
            width: 80,
            align: "center" as "center",
            render: (text, record) => (
                <div>
                    <Popover
                        content={
                            <MergeAttribute
                                record={record}
                                setAttributeList={setAttributeList}
                                currentCountryName={currentCountryName}
                                getData={getAttributeList}
                                attributeList={attributeList}
                                allAttributes={allAttributes}
                            />
                        }
                        trigger="click"
                        open={record.mergePopover}
                    >
                        <Button size="small" onClick={() => openPopoverForMerge(record.id)}>
                            Merge
                        </Button>
                    </Popover>

                    <Popconfirm placement="top" title={"Are sure?"} onConfirm={() => deleteAttribute(record)} okText="Yes" cancelText="No">
                        <Button size="small" danger style={{ marginTop: "5px" }}>
                            Delete
                        </Button>
                    </Popconfirm>
                </div>
            ),
        },
        {
            title: "",
            dataIndex: "id",
            width: 80,
            align: "center" as "center",
            render: (_, record) => (
                <Button
                    size="small"
                    //type={ record.isChanged ? 'default' : 'primary' }
                    type="primary"
                    onClick={() => saveChanges(record)}
                    //danger={ record.isChanged }
                    disabled={!record.isChanged}
                >
                    <span style={{ marginLeft: "2px" }}>Save </span>
                    <span style={{ marginLeft: "2px", opacity: record.isChanged ? 1 : 0 }}>!</span>
                </Button>
            ),
        },
    ];

    return (
        <div
            style={{
                border: "1px solid lightgray",
                borderRadius: "5px",
                margin: "0.3rem 0.5rem 0 0.5rem",
                height: "82vh",
                padding: "0.5rem",
            }}
        >
            {attributeList.length > 0 ? (
                <React.Fragment>
                    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                        <SortableContext items={attributeList.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                            <Table
                                bordered
                                rowKey="key"
                                components={{ body: { row: RowTable } }}
                                columns={columns}
                                dataSource={attributeList}
                                pagination={{ pageSize: 20, size: "small", showSizeChanger: false }}
                                scroll={{ y: `73vh` }}
                                //className="attribute-overview-main-table"
                                rowClassName={(record) => (record.id === attributeToGroup ? "attribute-to-group" : "")}
                                loading={loadingAttributes}
                            />
                        </SortableContext>
                    </DndContext>
                </React.Fragment>
            ) : (
                <div style={{ marginTop: "8rem" }}>
                    <Empty description={loadingAttributes ? <Spin /> : selectedCategory ? "No Data" : "Select Category"} />
                </div>
            )}
        </div>
    );
};

export default AttributeOverviewTable;
