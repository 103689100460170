import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Divider, Form, Input, Layout, Row, Select, Space, Statistic } from "antd";
import { RootStore } from "../../../Store";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import "../style/TopProductAttributes.css";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import { getAllCategoryL3 } from "../../../actions/category/categoryActions";
import { TreeCategoryType } from "../../prices/types/AllPricesTypes";
import ApplyToAll from "../components/TopProductsAttributes/ApplyToAll";
import { tableWidth } from "../helpers/TopProductAttributes/tableWidth";
import TopProductAttributesTable from "../components/TopProductsAttributes/TopProductAttributesTable";
import CreateNewValues from "../components/TopProductsAttributes/CrateNewValue";
import numberWithCommas from "../../../shared/utils/numberUtils";

export type InputType = "range" | "select" | "multiselect" | "yesno";

export type PrimaryAttributesType<T> = {
    id: number;
    max_value: string;
    min_value: string;
    type: InputType;
    [key: string]: string | number | T;
    attribute_values: T;
};

export type AttributesType = {
    attr_value: string;
    attribute_allowed_value_id: null | number;
    attribute_id: number;
    attribute_name: string;
    attribute_type: string;
    max_value: number;
    min_value: number;
    product_attribute_id: string;
};

export type ProductType = {
    attr_value: string | null;
    attribute_allowed_value_id: number | null;
    attribute_id: number;
    attribute_type: string;
    max_value: string;
    min_value: string;
    [key: string]: string | number | null | AttributesType[];
    product_attribute_id: number | null;
    product_id: number;
    attributes: AttributesType[];
};

const TopProductAttributes: React.FC = () => {
    const [primaryAttributes, setPrimaryAttributes] = useState<PrimaryAttributesType<{ allowed_value_id: number; value: string }>[]>([]);
    const [products, setProducts] = useState<ProductType[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState(1);
    const [selectedCategory, setSelectedCategory] = useState<number | undefined>();
    const [showApplyToAll, setShowApplyToAll] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<string | undefined>();
    const [callApiSameParam, setCallApiSameParam] = useState(1);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const stateProduct = useSelector((state: RootStore) => state);
    const currentCountryId = stateProduct.dataLs.country ? stateProduct.dataLs.country.countryId : 1;
    const currentCountryName = stateProduct.dataLs.country ? stateProduct.dataLs.country.currentCountry : "rs";
    //create new value
    const [openModal, setOpenModal] = useState(false);
    const [newAttributeValue, setNewAttributeValue] = useState<{ name: string | undefined; attribute_id: number | undefined; product: ProductType } | null>(null);
    const [form] = Form.useForm();

    const dispatch = useDispatch();

    useEffect(() => {
        const getPrimaryAttributes = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/prices/attributes/get-primary-attributes-by-l3?l3_id=${selectedCategory}&country_id=${currentCountryId}`);
                setPrimaryAttributes(data.data);
            } catch (err) {
                console.log(err);
            }
        };

        selectedCategory && getPrimaryAttributes();
    }, [selectedCategory, currentCountryId]);

    const getProducts = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/attributes/get-l3-products-primary-attributes?l3_id=${selectedCategory}&country_id=${currentCountryId}&page=${page}${
                    search && search.length > 0 ? `&search=${search}` : ""
                }`
            );
            setProducts(data.data.map((d: ProductType) => ({ ...d, attributes: d.attributes })));
            setLoading(false);
            setLoadingSearch(false);
        } catch (err) {
            console.log(err);
        }
    }, [selectedCategory, currentCountryId, page, search]);

    useEffect(() => {
        const timer = setTimeout(() => {
            selectedCategory && getProducts();
        }, 50);
        return () => {
            clearTimeout(timer);
        };
    }, [selectedCategory, getProducts, callApiSameParam]);

    useEffect(() => {
        const getCount = async () => {
            try {
                const { data } = await axios.get(
                    `${process.env.REACT_APP_URL_API}/prices/attributes/get-l3-products-primary-attributes-count?l3_id=${selectedCategory}&country_id=${currentCountryId}${
                        search && search.length > 0 ? `&search=${search}` : ""
                    }`
                );
                setCount(data.pages);
            } catch (err) {
                console.log(err);
            }
        };
        selectedCategory && getCount();
    }, [selectedCategory, currentCountryId, search, callApiSameParam]);

    useEffect(() => {
        dispatch(getAllCategoryL3(currentCountryId));
    }, [dispatch, currentCountryId]);

    const onChangeCategory = (e: number) => {
        setSelectedCategory(e);
        form.resetFields();
        setSearch(undefined);
    };

    const handleSearch = (e: { search: string }) => {
        // console.log(e.search);
        if (search?.trim().toLowerCase() === e.search.trim().toLowerCase()) {
            setLoadingSearch(true);
            setCallApiSameParam((curr) => curr + 1); // if search is not changed, useEffect will not call api
            return;
        }
        if (e.search.trim().toLowerCase().toLowerCase()) {
            setLoadingSearch(true);
            // getPrimaryAttributes(e.search.trim().toLowerCase());
            setShowApplyToAll(true);
            setSearch(e.search.trim().toLowerCase());
            setPage(1);
        } else {
            // getPrimaryAttributes(); !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            setShowApplyToAll(false);
            setSearch(undefined);
            setPage(1);
        }
    };

    const showModal = (attributeObj: { name: string | undefined; attribute_id: number | undefined; product: ProductType }) => {
        setNewAttributeValue(attributeObj);
        setOpenModal(true);
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    const onPaginationChange = (page: number) => {
        setPage(page);
    };

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Top Product attributes" />
            <CreateNewValues
                openModal={openModal}
                handleCancel={handleCancel}
                newAttributeValue={newAttributeValue}
                countries={/*countries*/ stateProduct.countries}
                //setAttributeList={setAttributeList}
                currentCountryName={currentCountryName}
                // attributeList={attributeList}
                primaryAttirbutes={primaryAttributes}
                setPrimaryAttributes={setPrimaryAttributes}
                products={products}
                setProducts={setProducts}
            />
            <div style={{ backgroundColor: "white", margin: "0.5rem 1rem", minHeight: "87vh" }}>
                <Divider>Attribute product list</Divider>
                <Row justify="center">
                    <Col span={23} style={{ border: "1px solid lightgray", backgroundColor: "whitesmoke", padding: "1rem", display: "flex", alignItems: "center", position: "relative" }}>
                        <Select
                            showSearch
                            placeholder="Select a category"
                            optionFilterProp="label"
                            options={stateProduct.categoriesL3.data?.map((l3: TreeCategoryType) => ({ label: `${l3.id}: ${l3[`name_${currentCountryName}`]}`, value: l3.id }))}
                            style={{ width: "300px" }}
                            onChange={onChangeCategory}
                        />
                        <Form form={form} onFinish={handleSearch}>
                            <Space.Compact style={{ marginLeft: "0.3rem", marginBottom: 0 }}>
                                <Form.Item name="search" style={{ margin: 0 }}>
                                    <Input placeholder="Search by product name" style={{ width: "400px" }} />
                                </Form.Item>
                                <Form.Item style={{ margin: 0 }}>
                                    <Button htmlType="submit" type="primary" icon={<SearchOutlined />} loading={loadingSearch} disabled={!selectedCategory} />
                                </Form.Item>
                            </Space.Compact>
                        </Form>
                        <Statistic
                            title="Total"
                            value={/*!loading && countAutomatched !== null ? numberWithCommas(countAutomatched) : "-"*/ count && numberWithCommas(String(count * 100).replace(".", ","))}
                            valueStyle={{ color: "green" }}
                            //loading={loading || countAutomatched === null}
                            style={{ position: "absolute", right: "50px" }}
                        />
                    </Col>

                    {showApplyToAll && primaryAttributes.length > 0 && (
                        <ApplyToAll
                            primaryAttributes={primaryAttributes}
                            currentCountryName={currentCountryName}
                            selectedCategory={selectedCategory}
                            search={search}
                            // getPrimaryAttributes={getPrimaryAttributes}
                            getProducts={getProducts}
                            setPrimaryAttributes={setPrimaryAttributes}
                            stateProduct={stateProduct}
                        />
                    )}

                    <Col span={tableWidth(primaryAttributes)}>
                        <Layout style={{ margin: "0.6rem 1rem" }}>
                            <Layout.Header className="ean-overview-header" />
                            <TopProductAttributesTable
                                primaryAttributes={primaryAttributes}
                                products={products}
                                setProducts={setProducts}
                                count={count}
                                currentCountryName={currentCountryName}
                                page={page}
                                loading={loading}
                                onPaginationChange={onPaginationChange}
                                //showModal={showModal}
                                showModal={showModal}
                            />
                        </Layout>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default TopProductAttributes;
