import React, { useCallback } from "react";
import { Alert, Button, Card, Col, Divider, Layout, Modal, Row, Spin, Table, Tag } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import HelmetTitle from "../../../shared/Head/HelmetTitle";
import showMessage from "../../../shared/MessagesInfo/message";
import numberWithCommas from "../../../shared/utils/numberUtils";
import "../style/PricesAndProducts.css";
import { useDispatch, useSelector } from "react-redux";
import { RootStore } from "../../../Store";
import { getTransform } from "../../../actions/transformAction/transformAction";
import Spinner from "../../../shared/Spinner/Spinner";
import moment from "moment";
import PricesProductsChart from "../components/PricesProductsChart";
import { DatePickerNewProducts } from "../components/NewShopProducts";
import { useSearchState } from "../../../shared/hooks/useSearchState";
import { BarChartOutlined } from "@ant-design/icons";
const { Content } = Layout;

type AdditionalType = {
    unprocessed_valid: number;
    valid: number;
    in_stock: number;
    difference_errored_prices: number;
    difference_in_stock_categorized_distinct: number;
    difference_products_in_xml: number;
    difference_valid: number;
    difference_valid_in_stock: number;
    created_at: string;
};

type CategorizedValidInStockType = {
    differences: AdditionalType;
};

type PricesProductsType = {
    categorized_valid_in_stock: number;
    id: number;
    in_stock: number;
    name: string;
    unsorted: number;
    valid: number;
    unprocessed_valid: number;
    isTransformLoading: boolean;
    isUpdateLoading: boolean;
    isPullLoading: boolean;
    isInsertLoading: boolean;
    recordSpinner: boolean;
};

type UpdateData = {
    count_before_update: number;
    current_valid_categorized_in_stock: number;
    current_valid_in_stock: number;
    current_valid_prices: number;
    errored_prices: number;
    shop_name: string;
    total_time: number;
    updated_prices: number;
    current_valid_categorized_in_stock_distinct: number;
    distinct_skus_and_valid_price: number;
    products_in_xml: number;
    method: string;
    new_data: { products_in_xml: number };
    errored_offers: number;
    exec_time: string;
};

let shopId: number;

const shwoPlus = (record: number) =>
    record > 0 ? <b style={{ color: "#175710" }}>{`+${numberWithCommas(record)}`}</b> : record === 0 ? "0" : <b style={{ color: "#e63941" }}>{numberWithCommas(record)}</b>;

const PricesAndProducts = () => {
    const [pricesProducts, setPricesProducts] = useState<PricesProductsType[]>([]);
    const [updateData, setUpdateData] = useState<UpdateData | null>(null);

    //const [ updateLoading, setUpdateLoading ] = useState(false)

    const [insertProductModal, setInsertProductModal] = useState(false);
    const [insertProductData, setInsertProductData] = useState({});
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    //////////////////////
    const [isChartModalVisible, setIsChartModalVisible] = useState(false);
    const [chartData, setChartData] = useState<{ data: []; message: string }>({ data: [], message: "" });
    const [date, setDate] = useState<DatePickerNewProducts>({ startDate: new Date(), endDate: new Date() });
    const [loadingSpinner, setLoadingSpinner] = useState(true);

    const showChartModal = useCallback(
        async (id: number) => {
            let formTo: { startDate: string; endDate: string };
            if (date.endDate) {
                formTo = {
                    startDate: moment(date.startDate).format("YYYY-MM-DD"),
                    endDate: moment(date.endDate).format("YYYY-MM-DD"),
                };
                try {
                    const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/price-update-service/get-shop-recent-history?shop_id=${id}&from=${formTo.startDate}&to=${formTo.endDate}`);
                    const newData = data.data.map((item: { created_at: string }) => {
                        return {
                            ...item,
                            dayInMonth: item.created_at.split(" ")[0],
                            timeInDay: item.created_at.split(" ")[1],
                        };
                    });
                    setChartData({ ...data, data: newData });
                    setIsChartModalVisible(true);
                } catch (err) {
                    console.log(err);
                }
            }
        },
        [date.endDate, date.startDate]
    );
    useEffect(() => {
        if (date.startDate && date.endDate && shopId) {
            showChartModal(shopId);
        }
    }, [date.endDate, date.startDate, showChartModal]);

    const handleChaartModalCancel = () => {
        setIsChartModalVisible(false);
    };
    ///////////////
    const shopState = useSelector((state: RootStore) => state);
    const dispatch = useDispatch();
    const currentCountryId = shopState.dataLs.country ? shopState.dataLs.country.countryId : 1;

    const closeInsertModal = () => {
        setInsertProductModal(false);
    };

    const [isModalUpdate, setIsModalUpdate] = useState(false);

    const handleOk = () => {
        setIsModalUpdate(false);
    };

    const handleCancel = () => {
        setIsModalUpdate(false);
    };
    //--------------- Transform modal-------------------//

    const [isModalTransformVisible, setIsModalTransformVisible] = useState(false);

    const handleTransformCancel = () => {
        setIsModalTransformVisible(false);
    };

    /////////////////////////////////

    const getPricesProducts = useCallback(
        async (load: boolean, type: string, id: number | null) => {
            if (type === "update") {
                //console.log(newData)
                setPricesProducts((curr) =>
                    curr.map((item) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                recordSpinner: true,
                            };
                        }
                        return item;
                    })
                );
            }
            load && setLoadingSpinner(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/price-update-service/get-active-shops?country_id=${currentCountryId}`);
                let tempArr = [];
                for (let el in data.data) {
                    tempArr.push({ ...data.data[el], isTransformLoading: false, isUpdateLoading: false, isPullLoading: false, isInsertLoading: false, recordSpinner: false });
                }
                //console.log(tempArr)
                setPricesProducts(tempArr);
                load && setLoadingSpinner(false);
            } catch (err) {
                console.log(err);
                load && setLoadingSpinner(false);
            }
        },
        [currentCountryId]
    );

    useEffect(() => {
        getPricesProducts(true, "", null);
    }, [getPricesProducts]);

    const updatePrices = async (id: number) => {
        const newData = pricesProducts.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isUpdateLoading: true,
                };
            }
            return item;
        });
        setPricesProducts(newData);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/price-update-service/v2/update-shop-prices?shop_id=${id}`);
            setUpdateData(data);

            setPricesProducts((current) =>
                current.map((item) => {
                    return {
                        ...item,
                        isUpdateLoading: false,
                    };
                })
            );
            setIsModalUpdate(true);
            setTimeout(() => {
                getPricesProducts(false, "update", id);
            }, 500);
        } catch (err) {
            console.log(err);
        }
    };

    const insertProduct = async (id: number) => {
        const newData = pricesProducts.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isInsertLoading: true,
                };
            }
            return item;
        });

        setPricesProducts(newData);
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/update-products`, { shop_ids: [id] });
            setInsertProductData(data.data);
            showMessage(data.message);
            setPricesProducts((current) =>
                current.map((item) => {
                    return {
                        ...item,
                        isInsertLoading: false,
                    };
                })
            );
            setInsertProductModal(true);
        } catch (err) {
            console.log(err);
        }
    };

    const sortTable = (a: any, b: any) => {
        if (isFinite(a - b)) {
            return a - b;
        } else if (a === 0 && b === 0) {
            return 1;
        } else {
            return isFinite(a) ? -1 : 1;
        }
    };

    const transformShop = (id: number) => {
        const newData = pricesProducts.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isTransformLoading: true,
                };
            }
            return item;
        });
        setPricesProducts(newData);
        dispatch(getTransform(id, setLoading, setIsModalTransformVisible));
    };

    const pullPrices = async (id: number) => {
        const newData = pricesProducts.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    isPullLoading: true,
                };
            }
            return item;
        });
        setPricesProducts(newData);
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_URL_API}/price-update-service/call-shop-prices-pull?shop_id=${id}`);
            showMessage(data.message);
            setPricesProducts((currentData) =>
                currentData.map((data) => {
                    return { ...data, isPullLoading: false };
                })
            );
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (!loading) {
            setPricesProducts((current) =>
                current.map((item) => {
                    return {
                        ...item,
                        isTransformLoading: false,
                    };
                })
            );
        }
    }, [loading]);

    const sortTableDate = (a: string, b: string, type: string) => {
        if (type === "date") {
            if (new Date(a).toISOString() < new Date(b).toISOString()) return -1;
            if (new Date(b).toISOString() < new Date(a).toISOString()) return 1;
            return 0;
        }
        if (a < b) return -1;
        if (b < a) return 1;
        return 0;
    };

    const { getColumnSearchProps } = useSearchState(pricesProducts);

    const columns: any = [
        {
            title: "#",
            dataIndex: "id",
            key: "id",
            render: (text: string, record: PricesProductsType, index: number) => index + 1 + (pageNumber - 1) * 100,
            align: "center" as "center",
            fixed: "left",
            width: "3%",
        },
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            className: "redirect-center",
            align: "center" as "center",
            fixed: "left",
            width: "3%",
            //filterDropdownOpen: true,
            ...getColumnSearchProps("id"),
        },
        {
            title: "",
            dataIndex: "",
            width: "2%",
            fixed: "left",
            className: "attribute-mapping-shop-att-td",
            render: (text: string, record: any) => (
                <BarChartOutlined
                    className="priceAndProductsChart"
                    onClick={() => {
                        showChartModal(record.id);
                        shopId = record.id;
                    }}
                />
            ),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            fixed: "left",
            width: "10%",
            className: "attribute-mapping-custom-td",
            ...getColumnSearchProps("name"),
            //render:(text:string, record:any) => !record.recordSpinner ? text : <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} size='small' style={{ display:'flex' ,justifyContent:'space-around' }} tip={`Loading data for ${ text }`} />
        },
        {
            title: "Categorized valid in stock",
            dataIndex: "categorized_valid_in_stock",
            key: "categorized_valid_in_stock",
            className: "redirect-center",
            align: "center" as "center",
            fixed: "left",
            sorter: (a: PricesProductsType, b: PricesProductsType) => a.categorized_valid_in_stock - b.categorized_valid_in_stock,
            render: (record: number) => numberWithCommas(record),
        },
        {
            title: "Categorized (percent)",
            dataIndex: "categorized_valid_in_stock",
            key: "categorized_valid_in_stock",
            className: "redirect-center",
            align: "center" as "center",
            fixed: "left",
            sorter: (a: any, b: any) => sortTable(100 - a.unprocessed_valid / a.valid, 100 - b.unprocessed_valid / b.valid),
            render: (text: string, record: any, index: number) => (
                <span style={{ color: "darkred" }} className="price-product-td-span">
                    {record.categorized_valid_in_stock !== 0 || record.in_stock !== 0 ? `${((record.categorized_valid_in_stock / record.in_stock) * 100).toFixed(2)} %` : "-"}
                </span>
            ),
        },
        {
            title: "Valid",
            dataIndex: "valid",
            key: "valid",
            className: "redirect-center",
            align: "center" as "center",
            fixed: "left",
            sorter: (a: PricesProductsType, b: PricesProductsType) => a.valid - b.valid,
            render: (record: number) => numberWithCommas(record),
        },
        {
            title: "In stock",
            dataIndex: "in_stock",
            key: "in_stock",
            className: "redirect-center",
            align: "center" as "center",
            fixed: "left",
            sorter: (a: PricesProductsType, b: PricesProductsType) => a.in_stock - b.in_stock,
            render: (record: number) => numberWithCommas(record),
        },
        {
            title: "Unsorted",
            dataIndex: "unsorted",
            key: "unsorted",
            className: "redirect-center",
            align: "center" as "center",
            fixed: "left",
            sorter: (a: PricesProductsType, b: PricesProductsType) => a.unsorted - b.unsorted,
            render: (record: number) => numberWithCommas(record),
        },

        {
            title: "Last Update at",
            dataIndex: "differences",
            key: "differences",
            className: "redirect-center prices-product-separator",
            align: "center" as "center",
            fixed: "left",
            width: "8%",
            sorter: (a: CategorizedValidInStockType, b: CategorizedValidInStockType) => a.differences && b.differences && sortTableDate(a.differences.created_at, b.differences.created_at, "date"),
            render: (record: any) => (record && record.created_at !== null ? `${moment(record.created_at).format("DD MMM YYYY,")} ${moment(record.created_at).format("HH:mm")}` : 0),
        },

        {
            title: "Difference errored prices",
            dataIndex: "differences",
            key: "differences",
            className: "redirect-center",
            align: "center" as "center",
            sorter: (a: CategorizedValidInStockType, b: CategorizedValidInStockType) =>
                a.differences && b.differences && a.differences.difference_errored_prices - b.differences.difference_errored_prices,
            render: (record: any) => (record && record.difference_errored_prices !== null ? shwoPlus(record.difference_errored_prices) : 0),
        },
        {
            title: "Difference in stock categorized distinct",
            dataIndex: "differences",
            key: "differences",
            className: "redirect-center",
            align: "center" as "center",
            sorter: (a: CategorizedValidInStockType, b: CategorizedValidInStockType) =>
                a.differences && b.differences && a.differences.difference_in_stock_categorized_distinct - b.differences.difference_in_stock_categorized_distinct,
            render: (record: any) => (record && record.difference_in_stock_categorized_distinct !== null ? shwoPlus(record.difference_in_stock_categorized_distinct) : 0),
        },
        {
            title: "Difference products in xml",
            dataIndex: "differences",
            key: "differences",
            className: "redirect-center",
            align: "center" as "center",
            sorter: (a: CategorizedValidInStockType, b: CategorizedValidInStockType) =>
                a.differences && b.differences && a.differences.difference_products_in_xml - b.differences.difference_products_in_xml,
            render: (record: any) => (record && record.difference_products_in_xml !== null ? shwoPlus(record.difference_products_in_xml) : 0),
        },
        {
            title: "Difference valid",
            dataIndex: "differences",
            key: "differences",
            className: "redirect-center",
            align: "center" as "center",
            sorter: (a: CategorizedValidInStockType, b: CategorizedValidInStockType) => a.differences && b.differences && a.differences.difference_valid - b.differences.difference_valid,
            render: (record: any) => (record && record.difference_valid !== null ? shwoPlus(record.difference_valid) : 0),
        },
        {
            title: "Difference valid in stock",
            dataIndex: "differences",
            key: "differences",

            className: "redirect-center prices-product-separator",
            align: "center" as "center",
            sorter: (a: CategorizedValidInStockType, b: CategorizedValidInStockType) =>
                a.differences && b.differences && a.differences.difference_valid_in_stock - b.differences.difference_valid_in_stock,
            render: (record: AdditionalType) => (record && record.difference_valid_in_stock !== null ? shwoPlus(record.difference_valid_in_stock) : 0),
        },

        {
            title: "Transform XML",
            dataIndex: "id",
            key: "id",
            className: "redirect-center",
            align: "center" as "center",
            width: "5%",
            render: (text: number, record: PricesProductsType, index: number) =>
                record.isTransformLoading ? (
                    <Spin size="small" />
                ) : (
                    <Button
                        size="small"
                        disabled={!!pricesProducts.find((item) => item.isTransformLoading === true)}
                        className="price-product-transform-btn"
                        onClick={() => transformShop(text)}
                        type="ghost"
                    >
                        Transform
                    </Button>
                ),
        },
        {
            title: "Insert Products",
            dataIndex: "id",
            key: "id",
            className: "redirect-center",
            width: "4.5%",
            align: "center" as "center",
            render: (text: number, record: PricesProductsType, index: number) =>
                record.isInsertLoading ? (
                    <Spin size="small" />
                ) : (
                    <Button
                        size="small"
                        disabled={!!pricesProducts.find((item) => item.isInsertLoading === true)}
                        className="price-product-insert-btn"
                        onClick={() => insertProduct(text)}
                        type="ghost"
                    >
                        Insert
                    </Button>
                ),
        },
        {
            title: "Update Prices",
            dataIndex: "id",
            key: "id",
            width: "4.8%",
            className: "redirect-center",
            align: "center" as "center",
            render: (text: number, record: PricesProductsType, index: number) =>
                record.isUpdateLoading ? (
                    <Spin size="small" />
                ) : (
                    <Button size="small" disabled={!!pricesProducts.find((item) => item.isUpdateLoading === true)} className="price-product-update-btn" onClick={() => updatePrices(text)} type="ghost">
                        Update
                    </Button>
                ),
        },
        {
            title: "Pull Prices",
            dataIndex: "id",
            key: "id",
            width: "3.4%",
            className: "redirect-center",
            align: "center" as "center",
            render: (text: number, record: PricesProductsType, index: number) =>
                record.isPullLoading ? (
                    <Spin size="small" />
                ) : (
                    <Button size="small" disabled={!!pricesProducts.find((item) => item.isPullLoading === true)} className="price-product-update-btn" onClick={() => pullPrices(text)} type="ghost">
                        Pull
                    </Button>
                ),
        },
    ];

    const paginationChange = (e: number) => {
        setPageNumber(e);
    };

    console.log(shopState.transform[0]);

    return (
        <React.Fragment>
            <HelmetTitle title="Data Entry App - Prices and products" />
            <Row justify={"center"}>
                <Col span={23} className="prices-products-box">
                    <Divider>Prices and products</Divider>
                    <Modal title="Update price" open={isModalUpdate} onOk={handleOk} onCancel={handleCancel} footer={false} width={600} style={{ display: "flex", justifyContent: "center" }}>
                        {updateData && (
                            <Card style={{ width: 500 }}>
                                <b>Shop: {updateData.shop_name}</b>
                                <br />
                                <hr />
                                <p>
                                    <b>Current valid categorized in stock distinct: {updateData.current_valid_categorized_in_stock_distinct}</b>
                                </p>
                                <p>Method: {updateData.method}</p>
                                <p>Products in xml: {updateData.new_data.products_in_xml}</p>
                                <p>Errored Prices: {updateData.errored_offers}</p>
                                <p>Total time: {updateData.exec_time}</p>
                            </Card>
                        )}
                        <Divider />
                        <Button type="primary" style={{ marginTop: "10px", marginLeft: "84%" }} onClick={handleCancel}>
                            Close
                        </Button>
                    </Modal>
                    <Modal title="Insert Products" open={insertProductModal} onOk={closeInsertModal} onCancel={closeInsertModal} footer={false}>
                        {Array.isArray(insertProductData) && insertProductData.length > 0 && (
                            <Card style={{ width: 300 }}>
                                <b>Shop id: {insertProductData[0].shop}</b>
                                <hr />
                                <p>Ean Matches: {insertProductData[0].ean_matches}</p>
                                <p>Execution Time: {insertProductData[0].execution_time}</p>
                                <p>New Prices: {insertProductData[0].new_prices}</p>
                                <hr />
                                <Tag color="warning" style={{ marginBottom: "1rem" }}>
                                    New Products
                                </Tag>
                                <p>Matched Brands: {insertProductData[0].new_products.matched_brands}</p>
                                <p>New Brands: {insertProductData[0].new_products.new_brands}</p>
                                <p>New Eans: {insertProductData[0].new_products.new_eans}</p>
                                <p>New Prices: {insertProductData[0].new_products.new_prices}</p>
                                <p>New Products: {insertProductData[0].new_products.new_products}</p>
                            </Card>
                        )}
                        <hr />
                        <button className="shop-list-btn" style={{ marginTop: "10px", marginLeft: "84%" }} onClick={() => setInsertProductModal(false)}>
                            Close
                        </button>
                    </Modal>
                    {isModalTransformVisible && (
                        <Modal
                            title={<p style={{ textAlign: "center" }}>{`shop Id - ${shopState.transform[0].data?.store_id ?? ""}`}</p>}
                            open={isModalTransformVisible} /*onOk={handleOk}*/
                            onCancel={handleTransformCancel}
                            footer={false}
                            style={{ width: 300, justifyContent: "center" }}
                        >
                            {shopState.transform[0].data ? (
                                <Card style={{ backgroundColor: "#fafafa" }}>
                                    <p>{`Transformation number - ${shopState.transform[0].data?.id}`}</p>
                                    <hr />
                                    <h6>Stats</h6>
                                    <br />
                                    <div>
                                        <p>
                                            Failed Count <span style={{ marginLeft: "48%" }}>{shopState.transform[0].data?.stats.failedCount}</span>{" "}
                                        </p>
                                        <p>
                                            Successful Count <span style={{ marginLeft: "41%" }}>{shopState.transform[0].data?.stats.successfulCount}</span>{" "}
                                        </p>
                                        <p>
                                            Total Products Count <span style={{ marginLeft: "35%" }}>{shopState.transform[0].data?.stats.totalProductsCount}</span>{" "}
                                        </p>
                                    </div>
                                    <hr />
                                    <h6>Transformation Time</h6>
                                    <br />
                                    <span>
                                        {shopState.transform[0].data?.file_type === "xml"
                                            ? shopState.transform[0].data?.stats.xmlTransformationTime.hours
                                            : shopState.transform[0].data?.stats.csvTransformationTime.hours}{" "}
                                    </span>
                                    hours{" "}
                                    <span>
                                        {shopState.transform[0].data?.file_type === "xml"
                                            ? shopState.transform[0].data?.stats.xmlTransformationTime.minutes
                                            : shopState.transform[0].data?.stats.csvTransformationTime.minutes}{" "}
                                    </span>
                                    minutes{" "}
                                    <span>
                                        {shopState.transform[0].data?.file_type === "xml"
                                            ? shopState.transform[0].data?.stats.xmlTransformationTime.seconds
                                            : shopState.transform[0].data?.stats.csvTransformationTime.seconds}{" "}
                                    </span>
                                    seconds{" "}
                                    <span>
                                        {shopState.transform[0].data?.file_type === "xml"
                                            ? shopState.transform[0].data?.stats.xmlTransformationTime.ms
                                            : shopState.transform[0].data?.stats.csvTransformationTime.ms}{" "}
                                    </span>
                                    ms
                                </Card>
                            ) : (
                                <Card>
                                    <Alert type="error" message={shopState.transform[0].error} />
                                </Card>
                            )}
                            <Button type="primary" style={{ marginTop: "10px", marginLeft: "84%" }} onClick={() => setIsModalTransformVisible(false)}>
                                Close
                            </Button>
                        </Modal>
                    )}
                    <PricesProductsChart isChartModalVisible={isChartModalVisible} handleChaartModalCancel={handleChaartModalCancel} chartData={chartData} date={date} setDate={setDate} />
                    <Layout style={{ padding: "0.4rem" }}>
                        {/* <Header className='product-img-header'><span></span> </Header> */}
                        <Content style={{ backgroundColor: "white" }}></Content>
                        {!loadingSpinner ? (
                            <Table
                                bordered
                                columns={columns}
                                dataSource={pricesProducts}
                                rowKey={(record: any) => record.id}
                                className="price-products-table"
                                rowClassName={(record) => (record.recordSpinner ? "price-product-loading-record" : "")}
                                scroll={{ x: "1630px" }}
                                pagination={{ pageSize: 100, showSizeChanger: false, onChange: paginationChange }}
                            />
                        ) : (
                            <div style={{ height: "80vh" }}>
                                <Spinner />
                            </div>
                        )}
                    </Layout>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PricesAndProducts;
