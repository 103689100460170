import { Button, Divider, Form, Input, Modal } from "antd";
import axios from "axios";
import React, { useState } from "react";
import showMessage from "../../../../shared/MessagesInfo/message";
import openNotification from "../../../../shared/MessagesInfo/WarningBox";
import { PrimaryAttributesType, ProductType } from "../../pages/TopProductAttributes";

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

type CreateNewValuesType = {
    openModal: boolean;
    newAttributeValue: { name: string | undefined; attribute_id: number | undefined; product: ProductType } | null;
    countries: { name: string; value: number }[];
    handleCancel: () => void;
    primaryAttirbutes: PrimaryAttributesType<{ allowed_value_id: number; value: string }>[];
    setPrimaryAttributes: React.Dispatch<React.SetStateAction<PrimaryAttributesType<{ allowed_value_id: number; value: string }>[]>>;
    //setAttributeList: React.Dispatch<React.SetStateAction<AttributeListType[]>>;
    currentCountryName: string;
    products: ProductType[];
    setProducts: React.Dispatch<React.SetStateAction<ProductType[]>>;
    //attributeList: AttributeListType[];
};

const CreateNewValues: React.FC<CreateNewValuesType> = ({
    openModal,
    handleCancel,
    newAttributeValue,
    countries,
    /*setAttributeList,*/ currentCountryName /*attributeList*/,
    primaryAttirbutes,
    products,
    setProducts,
    setPrimaryAttributes,
}) => {
    const [form] = Form.useForm();
    const [loadingNewValue, setLoadingNewValue] = useState(false);

    const saveValueForAttribute = async (newId: any, newValue: string) => {
        try {
            const checkIsAttributeAllowed = newAttributeValue?.product?.attributes.find((attr) => attr.attribute_id === newAttributeValue?.attribute_id);
            const objToSend = checkIsAttributeAllowed?.attribute_allowed_value_id
                ? {
                      product_id: newAttributeValue?.product.product_id,
                      type: checkIsAttributeAllowed.attribute_type,
                      // product_attribute_id: newAttributeValue?.product.product_attribute_id,
                      attribute_allowed_value_id: newId, /////
                      product_attribute_id: checkIsAttributeAllowed.product_attribute_id,
                      value: null,
                  }
                : {
                      attribute_id: newAttributeValue?.attribute_id,
                      product_id: newAttributeValue?.product.product_id,
                      attribute_allowed_value_id: newId,
                      value: null,
                  };
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/${checkIsAttributeAllowed?.product_attribute_id ? "update" : "assign"}-product-attribute`, objToSend);
            showMessage(data.message);
            setProducts((curr) =>
                curr.map((prod) =>
                    prod.product_id === newAttributeValue?.product.product_id
                        ? {
                              ...prod,
                              attributes: prod.attributes.map(
                                  (attr) =>
                                      attr.attribute_id === newAttributeValue?.attribute_id
                                          ? { ...attr, attr_value: newValue, attribute_allowed_value_id: newId, product_attribute_id: data.data }
                                          : attr // ?? here check attribute_allowed_value_id: newId
                              ) /*attr_value: newValue, attribute_allowed_value_id: newId*/,
                          }
                        : prod
                )
            );
        } catch (errInfo) {
            console.log("Save failed:", errInfo);
        }
    };

    const saveNewAttributeValues = async (e: any) => {
        const objToSend = {
            ...e,
            attribute_id: newAttributeValue?.attribute_id,
        };

        try {
            setLoadingNewValue(true);
            const { data } = await axios.post(`${process.env.REACT_APP_URL_API}/prices/attributes/create-attribute-value`, objToSend);
            if (data.data.already_exists) {
                openNotification("Attribute already exists");
            } else {
                setPrimaryAttributes((curr: any) =>
                    curr.map((attr: any) =>
                        attr.id === newAttributeValue?.attribute_id
                            ? { ...attr, attribute_values: [...attr.attribute_values, { allowed_value_id: data.data.values, value: e[`value_${currentCountryName}`] }] }
                            : attr
                    )
                );
                saveValueForAttribute(data.data.values, e[`value_${currentCountryName}`]);
                handleCancel();
                form.resetFields();
            }
            setLoadingNewValue(false);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <Modal
            title={
                <div>
                    <p style={{ textAlign: "center" }}>
                        New Value for <span style={{ color: "darkred" }}>{newAttributeValue?.name}</span>
                    </p>
                </div>
            }
            open={openModal}
            onCancel={handleCancel}
            footer={false}
        >
            <Divider />
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={saveNewAttributeValues}
                autoComplete="off"
                form={form}
            >
                {countries.map((c) => (
                    <Form.Item<FieldType>
                        label={c.name.toUpperCase()}
                        name={`value_${c.name}`}
                        rules={[{ required: true, message: `Please input your ${c.name.toUpperCase()} translate!` }]}
                        key={c.name}
                    >
                        <Input placeholder={`Attribute Value ${c.name.toUpperCase()}`} />
                    </Form.Item>
                ))}
                <Form.Item<FieldType> label={"EN"} name={`value_en`} rules={[{ required: true, message: `Please input your ${"EN"} translate!` }]} key={"en"}>
                    <Input placeholder={`Attribute Value ${"EN"}`} />
                </Form.Item>
                <Divider />
                <Form.Item style={{ display: "flex", justifyContent: "center" }}>
                    <Button type="primary" htmlType="submit" loading={loadingNewValue}>
                        Save New Value
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateNewValues;
