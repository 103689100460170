import { Button, Table } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import axios from "axios";
import React, { useState } from "react";
import { checkIsIncludes } from "../../helpers/checkIsIncludes";
import { ValuesType, ValueType } from "../../types/AttributeType";
import AllowValueModal from "./AllowValueModal";
import ShowProductsModal from "./ShowProductsModal";
import "../../style/ShopValuesTable.css";
const { Column, ColumnGroup } = Table;

interface ShopValuesTableProps {
    valuesShop: ValueType;
    values: ValueType;
    rangeToCompare: { min: number; max: number } | null;
    arrToCompare: string[];
    //showProducts: () =>void
    openModalAlloweValue: (record: any) => void;
    form: FormInstance<any>;
    handleOk: () => void;
    handleCancel: () => void;
    isModalOpen: boolean;
    saveNewAllowedValue: any;
    activeTabKey: number | null;
    selectedCategory: { id: number; name: string };
    countries: { name: string; value: number }[];
}

export type AttrProductsType = {
    default_image_url: string;
    id: number;
    name_rs: string;
    preview_text_rs: string;
};

const ShopValuesTable: React.FC<ShopValuesTableProps> = ({
    rangeToCompare,
    values,
    valuesShop,
    arrToCompare,
    openModalAlloweValue,
    form,
    handleOk,
    handleCancel,
    isModalOpen,
    saveNewAllowedValue,
    activeTabKey,
    selectedCategory,
    countries,
}) => {
    const [showProductsModal, setShowProductsModal] = useState(false);
    const [attrProducts, setAttrProducts] = useState<AttrProductsType[]>([]);

    const showProducts = async (record: ValuesType) => {
        try {
            const { data } = await axios.get(
                `${process.env.REACT_APP_URL_API}/prices/attributes/show-price-attributes-for-products?category_l3=${selectedCategory.id}&key=${valuesShop.name}&value=${record.value_rs}&shop_id=${activeTabKey}`
            );
            setAttrProducts(data.data);
        } catch (err) {
            console.log(err);
        }
        setShowProductsModal(true);
    };

    return (
        <React.Fragment>
            <ShowProductsModal showProductsModal={showProductsModal} setShowProductsModal={setShowProductsModal} attrProducts={attrProducts} setAttrProducts={setAttrProducts} />
            <div
                style={{
                    height: "100%",
                    overflow: "hidden",
                    overflowY: "auto",
                    position: "relative",
                    left: "10px",
                }}
                className="att-value-box"
            >
                <Table dataSource={valuesShop.data} style={{ marginTop: "8px" }} size="small" pagination={false} rowKey={(record) => record.id}>
                    <ColumnGroup
                        title={
                            <span style={{ fontSize: "16px" }}>
                                <span style={{ position: "absolute", left: "10px", color: values.type === "Attribute" ? "#f78536" : "#3baea0" }}>Shop {values.type}</span>
                                <br />
                                <span style={{ width: "100%", color: "black" }}>{values?.name}</span>
                            </span>
                        }
                    >
                        <Column
                            title="Value"
                            dataIndex="value_rs"
                            key="firstName"
                            width="40%"
                            render={(_, record: ValuesType) => {
                                return (
                                    <span style={{ color: checkIsIncludes(valuesShop, rangeToCompare, /*record?.value_rs,*/ arrToCompare, record?.value_rs) ? "green" : "red" }}>
                                        {record.value_rs}
                                    </span>
                                );
                            }}
                        />
                        <Column
                            title="Clean"
                            dataIndex="temporary_clean_value"
                            key="temporary_clean_value"
                            width="40%"
                            render={(text) => <span style={{ color: checkIsIncludes(valuesShop, rangeToCompare, /*record,*/ arrToCompare, text) ? "green" : "red" }}>{text}</span>}
                        />
                        <Column
                            title=""
                            dataIndex="value_rs"
                            key="value_rs"
                            render={(text, record: ValuesType) =>
                                !checkIsIncludes(valuesShop, rangeToCompare, /*record,*/ arrToCompare, record.temporary_clean_value) && values.attribute_type !== "range" ? (
                                    <Button
                                        size="small"
                                        style={{ marginRight: "0.5rem", display: valuesShop.isSelect ? (arrToCompare?.includes(String(record.value_rs)) ? "none" : "") : "" }}
                                        onClick={() => openModalAlloweValue(record)}
                                    >
                                        Allow
                                    </Button>
                                ) : (
                                    ""
                                )
                            }
                        />
                        <Column
                            title="Count"
                            dataIndex="shop_value_count"
                            key="shop_value_count"
                            width="17%"
                            // render={ (text ) => <span style={{ color: checkIsIncludes(valuesShop,rangeToCompare,/*record,*/ arrToCompare, text) ? 'green':'red'  }}>{ text }</span> }
                            render={(_, record: ValuesType) => (
                                <Button type="text" shape="round" size="small" onClick={() => showProducts(record)}>
                                    {record.shop_value_count}
                                </Button>
                            )}
                        />
                    </ColumnGroup>
                </Table>
            </div>
            <AllowValueModal form={form} handleOk={handleOk} handleCancel={handleCancel} isModalOpen={isModalOpen} saveNewAllowedValue={saveNewAllowedValue} values={values} countries={countries} />
        </React.Fragment>
    );
};

export default ShopValuesTable;
