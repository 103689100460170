import { Button, Checkbox, Divider, Form, Input, Radio, Row, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import showMessage from "../../../shared/MessagesInfo/message";
import { useDispatch } from "react-redux";
import openNotification from "../../../shared/MessagesInfo/WarningBox";
import { SaveOutlined } from "@ant-design/icons";
import { createPredefined, getPredefinedText } from "../api/apiData";
import { EditableNamePopoverProp } from "../types/props";

const EditableNamePopoverContent: React.FC<EditableNamePopoverProp> = (props) => {
    const [preDefTxt, setPreDefTxt] = useState("");
    const [predefinedArr, setPredefinedArr] = useState<{ [key: string]: string | number }[]>([]);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(() => {
        props.selectedCategory && getPredefinedText(props.selectedCategory, setPredefinedArr);
    }, [props.selectedCategory, dispatch]);

    const savePredefinedText = async () => {
        if (!props.selectedCategory) {
            openNotification("Category must be determinated");
        } else if (!preDefTxt) {
            openNotification("Predeftext must be determinated");
        } else {
            createPredefined({
                country_id: props.currentCountryId,
                l3_l4_id: props.selectedCategory,
                [`name_${props.currentCountryName}`]: preDefTxt,
            })
                .then((resp) => {
                    setPredefinedArr((curr) => [
                        ...curr,
                        {
                            id: resp.data.id,
                            [`name_${props.currentCountryName}`]: resp.data[`name_${props.currentCountryName}`],
                        },
                    ]);
                    showMessage(resp.message);
                    setPreDefTxt("");
                })
                .catch((err) => console.log(err));
        }
    };

    const addText = () => {
        //props.setPopoverVisible(false);
        const oldText = form.getFieldValue("old-text");
        const findText = predefinedArr.find((p) => p.id === Number(form.getFieldValue("predefined")));
        const newText = predefinedText
            ? findText
                ? findText[`name_${props.currentCountryName}`]
                : ""
            : form.getFieldValue("new-text");
        const textPosition = form.getFieldValue("textPosition") as "start" | "end" | "replace" | "replace_all";
        switch (textPosition) {
            case "start":
                props.setProducts((curr) =>
                    curr.map((p) => (p.isChecked ? { ...p, name: newText + ` ${p.name}` } : p))
                );
                break;
            case "end":
                props.setProducts((curr) =>
                    curr.map((p) => (p.isChecked ? { ...p, name: `${p.name} ` + newText } : p))
                );
                break;
            case "replace":
                props.setProducts((curr) =>
                    curr.map((p) => (p.isChecked ? { ...p, name: `${p.name}`.replace(oldText, newText) } : p))
                );
                break;
            case "replace_all":
                props.setProducts((curr) => curr.map((p) => (p.isChecked ? { ...p, name: newText } : p)));
                break;
            default:
                openNotification("Select text position!");
                break;
        }

        form.resetFields();
    };

    const textPosition = Form.useWatch("textPosition", form);
    const predefinedText = Form.useWatch("predefined-text", form);

    return (
        <div style={{ display: "flex", flexDirection: "column", marginTop: "1.5rem" }}>
            <Divider style={{ marginTop: 0, marginBottom: 10, padding: 0 }} />
            <Form onFinish={addText} initialValues={{ textPosition: "start" }} form={form}>
                {textPosition === "replace" && (
                    <Form.Item
                        label="Old Text"
                        name="old-text"
                        rules={[{ required: true, message: "Missing old text" }]}
                    >
                        <Input placeholder="Old Text" />
                    </Form.Item>
                )}
                <Form.Item
                    label="New Text"
                    name="new-text"
                    rules={[{ required: predefinedText ? false : true, message: "Missing new text" }]}
                >
                    <Input placeholder="New Text" />
                </Form.Item>
                <div
                    style={{
                        backgroundColor: "whitesmoke",
                        padding: "0.5rem 0.5rem 0.1rem 0.5rem",
                        display: "flex",
                    }}
                >
                    <Form.Item
                        name="predefined-text"
                        label="Predefined"
                        valuePropName="checked"
                        style={{ padding: 0, margin: 0 }}
                    >
                        <Checkbox />
                    </Form.Item>
                    {predefinedText && (
                        <Space.Compact style={{ width: "70%", marginLeft: "1rem" }}>
                            <Form.Item name="predefined" style={{ width: "100%", padding: 0, margin: 0 }}>
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ marginBottom: "0.5rem" }}
                                    placeholder="Choose predefined text"
                                    onKeyUp={(e: any) => setPreDefTxt(e.target.value)}
                                    filterOption={(input: string, option?: { label: string; value: string }) =>
                                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={predefinedArr.map((option) => ({
                                        value: String(option.id),
                                        label: String(option[`name_${props.currentCountryName}`]),
                                    }))}
                                />
                            </Form.Item>
                            <Button onClick={savePredefinedText} disabled={preDefTxt.trim().length > 0 ? false : true}>
                                <SaveOutlined />
                            </Button>
                        </Space.Compact>
                    )}
                </div>
                <Form.Item key="modelPosition" label="Position" name="textPosition">
                    <Radio.Group name="radiogroup">
                        <Radio value={"start"}>Start</Radio>
                        <Radio value={"end"}>End</Radio>
                        <Radio value={"replace"}>Replace</Radio>
                        <Radio value={"replace_all"}>Replace All</Radio>
                    </Radio.Group>
                </Form.Item>
                <Divider style={{ marginTop: 0, marginBottom: 10, padding: 0 }} />
                <Row justify="center">
                    <Button type="primary" htmlType="submit">
                        Add text
                    </Button>
                </Row>
            </Form>
        </div>
    );
};

export default EditableNamePopoverContent;
